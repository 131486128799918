import { Store } from 'src/types/_IStore'

export const initialStore: Store = {
	app: {
		isAuth: false,
		isLoaderActive: false
	},
	banners: {
		loading: false,
		data: null
	},
	contacts: {
		loading: false,
		data: null
	},

	logout: {
		loading: false
	},
	news: {
		data: null,
		detail: null
	},
	notifications: {
		data: null,
		messages: [],
		newMessages: [],
		filteredMessages: [],
		filter: 'all',
		range: []
	}
}
