
export interface ResponseAvailableNewPlanPreview {
  data: AvailableNewPlanPreview;
  status?: boolean;
  message: string;
  client_message: string;
}
export interface AvailableNewPlanPreview {
  service_title: string;
  service_type: string;
  condition_block: Condition | null;
  is_time_for_call: boolean;
  text: string[];
  is_btn_active: boolean;
  is_show_cost: boolean;
  contact_phone: string | null;
}
export interface ResponseAutopayConfirm {
  data: AutopayConfirm;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseAutopayment
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseAutopayment
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseAutopayment
   */
  client_message: string;
}

export interface AutopayConfirm {
  title: string | null;
  agreement: number;
  email: string;
  tooltip_date: string;
  tooltip_amount: string;
  tooltip_check_delivery: string;
  announces: Announce[] | null;
  redirect_url: string;
  autopay: Autopayment;
}

/**
 *
 * @export
 * @interface FetchArgs
 */
export interface FetchArgs {
  url: string;
  options: any;
}

/**
 * Дополнительные данные для услуги Телевидение, например, 'Частотный план'
 * @export
 * @interface AdditionalButtonAtv
 */
export interface AdditionalButtonAtv {
  /**
   * Имя кнопки/действия
   * @type {string}
   * @memberof AdditionalButtonAtv
   */
  caption: string;
  /**
   * Данные (ссылка на список)
   * @type {string}
   * @memberof AdditionalButtonAtv
   */
  data: string;
}
/**
 * Уникальное оповещение для клиента
 * @export
 * @interface Announce
 */
export interface Announce {
  /**
   * Порядковый номер сообщения
   * @type {number}
   * @memberof Announce
   */
  announce_id: number;
  /**
   * Сообщение
   * @type {string}
   * @memberof Announce
   */
  message: string;
}
/**
 * Телевидение (базовый)
 * @export
 * @interface AtvGroup
 */
export interface AtvGroup {
  /**
   * Название услуги
   * @type {string}
   * @memberof AtvGroup
   */
  service_title?: string;
  /**
   * Список услуг Телевидение (Базовый)
   * @type {Array<ServiceAtv>}
   * @memberof AtvGroup
   */
  services?: Array<ServiceAtv>;
}
/**
 *
 * @export
 * @interface AuthRefreshRequest
 */
export interface AuthRefreshRequest {
  /**
   * Токен
   * @type {string}
   * @memberof AuthRefreshRequest
   */
  refresh: string;
  login: string | number;
}
/**
 *
 * @export
 * @interface AuthRequest
 */
export interface AuthRequest {
  /**
   * Логин (номер договора)
   * @type {number}
   * @memberof AuthRequest
   */
  username: number;
  /**
   * Пароль
   * @type {string}
   * @memberof AuthRequest
   */
  password: string;
}
/**
 * Данные для изменения автоплатежа
 * @export
 * @interface AutopayChangeRequest
 */
export interface AutopayChangeRequest {
  /**
   * Сумма платежа
   * @type {number}
   * @memberof AutopayChangeRequest
   */
  amount?: number;
  /**
   * Дата (день) платежа
   * @type {number}
   * @memberof AutopayChangeRequest
   */
  date?: number;
}
/**
 * Информация об Автоплатеже
 * @export
 * @interface Autopayment
 */
export interface Autopayment {
  status: string | null;
  agreement: number;
  id_autopay: number;
  condition_block: Condition | null;
  /**
   * Подключен или не подключен автоплатеж
   * @type {boolean}
   * @memberof Autopayment
   */
  is_active: boolean;
  /**
   * Номер карты (последние 4 цифры)
   * @type {string}
   * @memberof Autopayment
   */
  card_num: string;
  /**
   * Дата списания (число месяца)
   * @type {number}
   * @memberof Autopayment
   */
  debit_date?: number;
  /**
   * Сумма платежа
   * @type {number}
   * @memberof Autopayment
   */
  amount?: number;
  /**
   * Адрес отправки чека
   * @type {string}
   * @memberof Autopayment
   */
  check_email: string;
  /**
   * Cтатус автоплатежа  * `not_connected` - Не подключен * `active` - Активен * `paused` - Приостановлен * `not_set` -
   * @type {string}
   * @memberof Autopayment
   */
  autopay_status: AutopayStatusEnum;
  /**
   * Сообщение о статусе автоплатежа
   * @type {string}
   * @memberof Autopayment
   */
  msg_autopay: string;
  show_delete_autopay_btn: boolean;
  show_change_autopay_btn: boolean;
}

/**
 * @export
 * @namespace Autopayment
 */
export enum AutopayStatusEnum {
  NotConnected = <any>'not_connected',
  Active = <any>'active',
  Paused = <any>'paused',
  NotSet = <any>'not_set',
}
/**
 *
 * @export
 * @interface AvailablePlans
 */
export interface AvailablePlans {
  service_type: string;
  /**
   * Код категории для фильтрации по вкладкам
   * @type {string}
   * @memberof AvailablePlans
   */
  category_code: string;
  is_block: boolean;
  /**
   * Имя категории для фильтрации по вкладкам
   * @type {string}
   * @memberof AvailablePlans
   */
  category_name: string;
  /**
   * Порядковый номер отображения категории
   * @type {number}
   * @memberof AvailablePlans
   */
  display_order: number;
  /**
   * Список доступных опций / тарифов
   * @type {Array<PlanItem>}
   * @memberof AvailablePlans
   */
  items?: Array<PlanItem>;
}
/**
 * Доступная новая услуга
 * @export
 * @interface AvailableService
 */
export interface AvailableService {
  order: number;
  /**
   * Тип услуги (внутренний идентификатор)  * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `atv` - Телевидение * `dtv` - ЦТВ * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение REC * `ott` - Онлайн ТВ * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_atv` - Телевидение * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof AvailableService
   */

  service_type: ServiceTypeEnum;
  /**
   * Имя услуги
   * @type {string}
   * @memberof AvailableService
   */
  name: string;
  /**
   * Описание услуги
   * @type {string}
   * @memberof AvailableService
   */
  description: string;
  /**
   * Признак специальной акции / предложения
   * @type {boolean}
   * @memberof AvailableService
   */
  is_promo: boolean;
  /**
   * Количество доступных тарифов для услуги
   * @type {number}
   * @memberof AvailableService
   */
  plans_count: number;
  /**
   * Текст на кнопке
   * @type {string}
   * @memberof AvailableService
   */
  plans_btn_text: string;
}

/**
 * @export
 * @namespace AvailableService
 */

/**
 * Доступные услуги с тарифами для клиента
 * @export
 * @interface AvailableServicesList
 */
export interface AvailableServicesList {
  /**
   * Список доступных услуг
   * @type {Array<AvailableService>}
   * @memberof AvailableServicesList
   */
  available_services: Array<AvailableService>;
}
/**
 *
 * @export
 * @interface BaseResponse
 */
export interface BaseResponse {
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof BaseResponse
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof BaseResponse
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof BaseResponse
   */
  client_message: string;
  /**
   * Полезная информация для вывода данных
   * @type {{ [key: string]: any; }}
   * @memberof BaseResponse
   */
  data?: { [key: string]: any };
}
/**
 * Информации о денежных средствах
 * @export
 * @interface Billing
 */
export interface Billing {
  email: string;
  agreement: number;
  other_payments: {
    show_link_url: boolean;
    text: string;
    payment_url: string;
  };
  condition_block: Condition | null;

  /**
   * Текущий баланс клиента
   * @type {number}
   * @memberof Billing
   */
  balance: number;
  /**
   * Общая стоимость по всем услугам в месяц
   * @type {number}
   * @memberof Billing
   */
  total_cost: number;
  /**
   * Ссылка на платежную страницу
   * @type {string}
   * @memberof Billing
   */
  payment_url: string;
  /**
   * Информация об автоплатеже
   * @type {Array<Autopayment>}
   * @memberof Billing
   */
  autopay_info: IAutopay;
  /**
   * Информация о кредите
   * @type {Credit}
   * @memberof Billing
   */
  credit_info: Credit;
  /**
   * Cтатус баланса  * `suspended` - Обслуживание приостановлено * `active` - Услуги предоставляются * `recharge` - Необходимо пополнить баланс * `blocked` - Услуги заблокированы * `not_set` -
   * @type {string}
   * @memberof Billing
   */
  balance_status: BalanceStatusEnum;
  /**
   * Сообщение о статусе баланса
   * @type {string}
   * @memberof Billing
   */
  msg_balance: string;
  /**
   * Рекомендуемый платеж по договору, если 0 - то рекомендации не выводим
   * @type {number}
   * @memberof Billing
   */
  recommended_payment: number;
}

export interface IAutopay {
  title: string | null;
  description: string | null;
  condition_block: Condition | null;

  autopays: Autopayment[] | null;
}
export interface Binfo {
  agreement: number;
  condition_block: Condition | null;

  /**
   * Текущий баланс клиента
   * @type {number}
   * @memberof Billing
   */
  balance: number;
  /**
   * Общая стоимость по всем услугам в месяц
   * @type {number}
   * @memberof Billing
   */
  total_cost: number;
  /**
   * Ссылка на платежную страницу
   * @type {string}
   * @memberof Billing
   */
  payment_url: string;
  /**
   * Информация об автоплатеже
   * @type {Array<Autopayment>}
   * @memberof Billing
   */
  autopay_info: Autopayment;
  /**
   * Информация о кредите
   * @type {Credit}
   * @memberof Billing
   */
  credit_info: Credit;
  /**
   * Cтатус баланса  * `suspended` - Обслуживание приостановлено * `active` - Услуги предоставляются * `recharge` - Необходимо пополнить баланс * `blocked` - Услуги заблокированы * `not_set` -
   * @type {string}
   * @memberof Billing
   */
  balance_status: BalanceStatusEnum;
  /**
   * Сообщение о статусе баланса
   * @type {string}
   * @memberof Billing
   */
  msg_balance: string;
  /**
   * Рекомендуемый платеж по договору, если 0 - то рекомендации не выводим
   * @type {number}
   * @memberof Billing
   */
  recommended_payment: number;
}

/**
 * @export
 * @namespace Billing
 */
export enum BalanceStatusEnum {
  Suspended = <any>'suspended',
  Active = <any>'active',
  Recharge = <any>'recharge',
  Blocked = <any>'blocked',
  NotSet = <any>'not_set',
  Empty = <any>'',
  Null = <any>'null',
}
/**
 * Получение данных о клиенте для блокировки услуги - Приостановть
 * @export
 * @interface BlockServiceGet
 */
export interface BlockServiceGet {
  days_limit: number;
  /**
   * Название услуги
   * @type {string}
   * @memberof BlockServiceGet
   */
  service_title: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof BlockServiceGet
   */
  service_cid: string;
  /**
   * Тип заявления - приостановка / возобновление  * `block` - Приостановка * `unblock` - Возобновление
   * @type {string}
   * @memberof BlockServiceGet
   */
  action: BlockServiceGet.ActionEnum;
  /**
   * Адрес подключения
   * @type {string}
   * @memberof BlockServiceGet
   */
  connection_address: string;
  /**
   * ФИО
   * @type {string}
   * @memberof BlockServiceGet
   */
  full_name?: string;
  /**
   * Номер договора
   * @type {number}
   * @memberof BlockServiceGet
   */
  agreement: number;
  /**
   * Номер телефона
   * @type {string}
   * @memberof BlockServiceGet
   */
  contact_phone: string;
  /**
   * Код страницы условий для Приостаановки услуг
   * @type {string}
   * @memberof BlockServiceGet
   */
  condition_block: Condition | null;

  /**
   * Подсказка (тултип) для Даты запуска приостановки
   * @type {string}
   * @memberof BlockServiceGet
   */
  tooltip_start_date?: string;
  /**
   * Подсказка (тултип) для Даты окончания приостановки
   * @type {string}
   * @memberof BlockServiceGet
   */
  tooltip_end_date?: string;
}

/**
 * @export
 * @namespace BlockServiceGet
 */
export namespace BlockServiceGet {
  /**
   * @export
   * @enum {string}
   */
  export enum ActionEnum {
    Block = <any>'block',
    Unblock = <any>'unblock',
  }
}
/**
 *
 * @export
 * @interface BlockServiceRequest
 */
export interface BlockServiceRequest {
  /**
   * Адрес подключения
   * @type {string}
   * @memberof BlockServiceRequest
   */
  connection_address: string;
  /**
   * ФИО
   * @type {string}
   * @memberof BlockServiceRequest
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof BlockServiceRequest
   */
  contact_phone: string;
  /**
   * Дата начала блокировки
   * @type {string}
   * @memberof BlockServiceRequest
   */
  start_block_date: string;
  /**
   * Дата окончания блокировки
   * @type {string}
   * @memberof BlockServiceRequest
   */
  end_block_date: string;
}
/**
 * Список каналов для пакетов ДТВ и ОТТ
 * @export
 * @interface Channels
 */
export interface Channels {
  /**
   * Идентификатор (код) пакета
   * @type {string}
   * @memberof Channels
   */
  key_packet: string;
  /**
   * Описание пакета
   * @type {string}
   * @memberof Channels
   */
  description: string;
  /**
   * Имя пакета
   * @type {string}
   * @memberof Channels
   */
  packet_name: string;
  /**
   * Список услуг Телевидение (Базовый)
   * @type {Array<DtvOttChannel>}
   * @memberof Channels
   */
  channels?: Array<DtvOttChannel>;
}
/**
 *
 * @export
 * @interface Charge
 */
export interface Charge {
  /**
   * Дата начисления / платежа DD.MM.YYYY
   * @type {string}
   * @memberof Charge
   */
  date: string;
  /**
   * Название категории начисления
   * @type {string}
   * @memberof Charge
   */
  name: string;
  /**
   * Сумма начисления
   * @type {number}
   * @memberof Charge
   */
  amount_charge: number;
  /**
   * Сумма платежа
   * @type {number}
   * @memberof Charge
   */
  amount_payment: number;
}
/**
 *
 * @export
 * @interface ChargeInfo
 */
export interface ChargeInfo {
  /**
   * Начальная дата DD.MM.YYYY
   * @type {string}
   * @memberof ChargeInfo
   */
  start_date: string;
  /**
   * Конечная дата DD.MM.YYYY
   * @type {string}
   * @memberof ChargeInfo
   */
  end_date: string;
  /**
   * Начальная сумма
   * @type {number}
   * @memberof ChargeInfo
   */
  start_balance: number;
  /**
   * Конечная сумма
   * @type {number}
   * @memberof ChargeInfo
   */
  end_balance: number;
  /**
   * Список начислений / платежей
   * @type {Array<Charge>}
   * @memberof ChargeInfo
   */
  charges?: Array<Charge>;
}
/**
 * Клиент (главный экран)
 * @export
 * @interface Client
 */
export interface Client {
  /**
   * ЛК только для чтения
   * @type {boolean}
   * @memberof Client
   */
  lk_readonly: boolean;
  /**
   * Если True - юридическое лицо
   * @type {boolean}
   * @memberof Client
   */
  is_juridical: boolean;
  /**
   * ФИО клиента
   * @type {string}
   * @memberof Client
   */
  fio: string;
  /**
   * Внутренний идентификатор адреса
   * @type {number}
   * @memberof Client
   */
  building_id: number;
  /**
   * Адрес (дом)
   * @type {string}
   * @memberof Client
   */
  building_name: string;
  /**
   * В номере квартиры может быть не только цифра, например, 5А
   * @type {string}
   * @memberof Client
   */
  flat: string;
  /**
   * Номер комнаты
   * @type {string}
   * @memberof Client
   */
  apart: string;
  /**
   * Номер договора
   * @type {number}
   * @memberof Client
   */
  agreement: number;
  /**
   * Уникальные оповещения для клиента
   * @type {Array<Announce>}
   * @memberof Client
   */
  announces: Array<Announce>;
  /**
   * Информация о денежных средствах
   * @type {Billing}
   * @memberof Client
   */
  binfo: Binfo;
  /**
   * Список всех подключенных услуг клиента
   * @type {ServicesClient}
   * @memberof Client
   */
  services: ServicesClient;
  /**
   * Список доступных услуг для подключения
   * @type {Array<AvailableService>}
   * @memberof Client
   */
  available_services: Array<AvailableService>;
  /**
   * Список уведомлений
   * @type {Array<Notification>}
   * @memberof Client
   */
  notifications: Array<Notification>;
}
/**
 *
 * @export
 * @interface ClientReceptionOffices
 */
export interface ClientReceptionOffices {
  /**
   * Часы работы офиса приема клиентов
   * @type {string}
   * @memberof ClientReceptionOffices
   */
  working_hours: string;
  /**
   * Список офисов приема клиентов
   * @type {Array<Office>}
   * @memberof ClientReceptionOffices
   */
  offices: Array<Office>;
}
/**
 * Телевидение (коллективное)
 * @export
 * @interface CollectiveAtvGroup
 */
export interface CollectiveAtvGroup {
  /**
   * Название услуги
   * @type {string}
   * @memberof CollectiveAtvGroup
   */
  service_title?: string;
  /**
   * Список услуг Телевидение (Коллективное)
   * @type {Array<ServiceCollectiveAtv>}
   * @memberof CollectiveAtvGroup
   */
  services?: Array<ServiceCollectiveAtv>;
}
/**
 *
 * @export
 * @interface Contact
 */
export interface Contact {
  /**
   * Название контакта
   * @type {string}
   * @memberof Contact
   */
  title: string;
  /**
   * Номер телефона
   * @type {string}
   * @memberof Contact
   */
  phone: string;
  /**
   * Часы работы
   * @type {string}
   * @memberof Contact
   */
  working_hours: string;
}
/**
 *
 * @export
 * @interface ContactsResponse
 */
export interface ContactsResponse {
  privacy_policy_url: string;
  feedback_url: string;

  telegram_url: string;
  /**
   * Информация о контактном центре
   * @type {Contact}
   * @memberof ContactsResponse
   */
  contact_center: Contact;
  /**
   * Информация о технической поддержке
   * @type {Contact}
   * @memberof ContactsResponse
   */
  technical_support: Contact;
  /**
   * Информация об офисах приема клиентов
   * @type {ClientReceptionOffices}
   * @memberof ContactsResponse
   */
  client_reception_offices: ClientReceptionOffices;
  /**
   * URL официального сайта
   * @type {string}
   * @memberof ContactsResponse
   */
  website_url: string;
  /**
   * URL на YouTube
   * @type {string}
   * @memberof ContactsResponse
   */
  youtube_url: string;
  /**
   * URL страницы в ВКонтакте
   * @type {string}
   * @memberof ContactsResponse
   */
  vk_url: string;
}
/**
 * Информации об Условном платеже
 * @export
 * @interface Credit
 */

export interface Credit {
  agreement: number;
  is_active: boolean;
  status: string | null;
  /**
   * Показывать ли кнопку Подключить
   * @type {boolean}
   * @memberof Credit
   */
  is_credit_available: boolean;
  amount: number | null;

  credit_duration: number | null;
  announces: Announce[] | null;
  credit_start_date: string | null;
  credit_expiry_date: string | null;
  description_deferral_period: string | null;
  time_remaining: string | null;
  credit_status: CreditStatusEnum;
  msg_credit: string;

  condition_block: Condition | null;

  /**
   * Текст ошибки о невозможности взять условный платеж.
   * @type {string}
   * @memberof Credit
   */
  notification_error: string | null;
  /**
   * Подключен или не подключен условный платеж
   * @type {boolean}
   * @memberof Credit
   */
}

export enum CreditStatusEnum {
  NotConnected = 'not_connected',
  Active = 'active',
  Debt = 'debt',
  Unavailable = 'unavailable',
  NotSet = 'not_set',
}
/**
 *
 * @export
 * @interface DocsOneTypeList
 */
export interface DocsOneTypeList {
  /**
   * Список документов
   * @type {Array<Document>}
   * @memberof DocsOneTypeList
   */
  documents: Array<Document>;
}
/**
 *
 * @export
 * @interface Document
 */
export interface Document {
  /**
   * Внутренний идентификатор документа
   * @type {number}
   * @memberof Document
   */
  id: number;
  /**
   * Имя документа
   * @type {string}
   * @memberof Document
   */
  name: string;
  /**
   * Тип документа  * `contract` - Договор * `invoice` - Счет
   * @type {string}
   * @memberof Document
   */
  type_doc: TypeDocEnum;
  /**
   * Тип файла
   * @type {string}
   * @memberof Document
   */
  mime_type: string;
  /**
   * Размер файла
   * @type {string}
   * @memberof Document
   */
  size: string;
  /**
   * Дата заключения договора. Может быть пустой.
   * @type {string}
   * @memberof Document
   */
  start_date: string;
  /**
   * Ссылка на скачивание документа
   * @type {string}
   * @memberof Document
   */
  url_download: string;
}

/**
 * @export
 * @namespace Document
 */
export enum TypeDocEnum {
  Contract = <any>'contract',
  Invoice = <any>'invoice',
}
/**
 *
 * @export
 * @interface DocumentList
 */
export interface DocumentList {
  /**
   * Список договоров
   * @type {Array<Document>}
   * @memberof DocumentList
   */
  contracts: Array<Document>;
  /**
   * Список счетов
   * @type {Array<Document>}
   * @memberof DocumentList
   */
  invoices: Array<Document>;
}
/**
 * ЦТВ (цифровое телевидение)
 * @export
 * @interface DtvGroup
 */
export interface DtvGroup {
  /**
   * Название услуги
   * @type {string}
   * @memberof DtvGroup
   */
  service_title?: string;
  /**
   * Список услуг ЦТВ
   * @type {Array<ServiceDtvOtt>}
   * @memberof DtvGroup
   */
  services?: Array<ServiceDtvOtt>;
}
/**
 *
 * @export
 * @interface DtvOttChannel
 */
export interface DtvOttChannel {
  /**
   *
   * @type {string}
   * @memberof DtvOttChannel
   */
  name: string;
  /**
   *
   * @type {boolean}
   * @memberof DtvOttChannel
   */
  is_hd?: boolean;
}
/**
 * Электронная почта клиента
 * @export
 * @interface Email
 */
export interface Email {
  /**
   * Адрес электронной почты
   * @type {string}
   * @memberof Email
   */
  email: string | null;
  /**
   * Запрет / разрешение на изменение email
   * @type {boolean}
   * @memberof Email
   */
  is_change_email: boolean;
  /**
   * Статус подтверждения email
   * @type {boolean}
   * @memberof Email
   */
  is_email_verified: boolean;
}
/**
 *
 * @export
 * @interface EmailRequest
 */
export interface EmailRequest {
  /**
   * Электронная почта
   * @type {string}
   * @memberof EmailRequest
   */
  email: string;
}
/**
 *
 * @export
 * @interface EmailUpdateRequest
 */
export interface EmailUpdateRequest {
  /**
   * Старая электронная почта
   * @type {string}
   * @memberof EmailUpdateRequest
   */
  old_email: string;
  /**
   * Новая электронная почта
   * @type {string}
   * @memberof EmailUpdateRequest
   */
  new_email: string;
}
/**
 * Список дополнительного оборудования
 * @export
 * @interface Equipment
 */
export interface Equipment {
  /**
   * Название дополнительной опции - Дополнительное оборудование
   * @type {string}
   * @memberof Equipment
   */
  title?: string;
  /**
   * Список дополнительного оборудования
   * @type {Array<EquipmentDetail>}
   * @memberof Equipment
   */
  equipments?: Array<EquipmentDetail>;
}
/**
 * Информация о дополнительном оборудовании
 * @export
 * @interface EquipmentDetail
 */
export interface EquipmentDetail {
  /**
   * Показывать ли кнопку Выкуп оборудования
   * @type {boolean}
   * @memberof EquipmentDetail
   */
  show_equipment_payback_btn: boolean;
  /**
   * Сумма выкупа
   * @type {number}
   * @memberof EquipmentDetail
   */
  buyout_cost: number;
  /**
   * Примерная сумма расчета выкупа
   * @type {string}
   * @memberof EquipmentDetail
   */
  buyout_cost_description: string;
  /**
   * Наименование оборудования
   * @type {string}
   * @memberof EquipmentDetail
   */
  name_equipment: string;
  /**
   * Статус аренды - в аренде или в собственности  * `on_lease` - В аренде * `owned` - В собственности * `not_set` -
   * @type {string}
   * @memberof EquipmentDetail
   */
  status: StatusEnum;
  /**
   * Сумма покупки оборудования
   * @type {number}
   * @memberof EquipmentDetail
   */
  cost_equipment: number;
  /**
   *
   * @type {RentDopEquipments}
   * @memberof EquipmentDetail
   */
  rent?: RentDopEquipments;
  /**
   * Количество камер (только для Видеонаблюдения)
   * @type {number}
   * @memberof EquipmentDetail
   */
  number_of_cameras?: number;
}

/**
 * @export
 * @namespace EquipmentDetail
 */

export enum StatusEnum {
  OnLease = <any>'on_lease',
  Owned = <any>'owned',
  NotSet = <any>'not_set',
}

export namespace EquipmentDetail {
  /**
   * @export
   * @enum {string}
   */
  export enum StatusEnum {
    OnLease = <any>'on_lease',
    Owned = <any>'owned',
    NotSet = <any>'not_set',
  }
}
/**
 *
 * @export
 * @interface ErrorResponse
 */
export interface ErrorResponse {
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ErrorResponse
   */
  status?: boolean;
  /**
   * Сообщение об ошибке
   * @type {string}
   * @memberof ErrorResponse
   */
  message: string;
  /**
   * Сообщение об ошибке для отображения пользователю
   * @type {string}
   * @memberof ErrorResponse
   */
  client_message: string;
  /**
   * Информация об ошибках
   * @type {string}
   * @memberof ErrorResponse
   */
  errors: string;
}
/**
 *
 * @export
 * @interface FileDownload
 */
export interface FileDownload {
  /**
   * Загруженный файл
   * @type {string}
   * @memberof FileDownload
   */
  file: string;
}
/**
 * Целевая услуга
 * @export
 * @interface FocusServiceGroup
 */
export interface FocusServiceGroup {
  /**
   * Название услуги
   * @type {string}
   * @memberof FocusServiceGroup
   */
  service_title: string;
  /**
   * Список услуг
   * @type {Array<{ [key: string]: unknown; }>}
   * @memberof FocusServiceGroup
   */
  services?: Array<unknown>;
}
/**
 * Дополнительная информация по услуге Телевидение для абонемента
 * @export
 * @interface InfoTvAbonement
 */
export interface InfoTvAbonement {
  /**
   * Текст заголовка для доп.информации по тарифу Абонемент
   * @type {string}
   * @memberof InfoTvAbonement
   */
  label: string;
  /**
   * Текст доп.информации по тарифу Абонемент
   * @type {string}
   * @memberof InfoTvAbonement
   */
  title: string;
}
/**
 * Тарифы для выбранной услуги
 * @export
 * @interface MainAvailablePlans
 */
export interface MainAvailablePlans {
  spec_offers: ISpecOffers;
  condition_block: Condition;
  /**
   * Имя услуги
   * @type {string}
   * @memberof MainAvailablePlans
   */
  service_title: string;
  /**
   * Тип услуги (внутренний идентификатор)
   * @type {string}
   * @memberof MainAvailablePlans
   */
  service_type: string;
  /**
   * Динамически сгенерированные планы по категориям.
   * @type {{ [key: string]: AvailablePlans; }}
   * @memberof MainAvailablePlans
   */
  available_plans: { [key: string]: AvailablePlans };
}
/**
 * Уведомление для клиента
 * @export
 * @interface Notification
 */
export interface Notification {
  /**
   * Уникальный идентификатор уведомления
   * @type {number}
   * @memberof Notification
   */
  notif_id: number;
  /**
   * Дата и время создания уведомления
   * @type {Date}
   * @memberof Notification
   */
  created_at: Date;
  /**
   * Заголовок уведомления
   * @type {string}
   * @memberof Notification
   */
  title: string;
  /**
   * Тело сообщения уведомления
   * @type {string}
   * @memberof Notification
   */
  body: string;
  /**
   * Тип уведомления  * `system` - Системное уведомление * `default` -
   * @type {string}
   * @memberof Notification
   */
  notif_type: Notification.NotifTypeEnum;
  /**
   * Статус прочтения уведомления
   * @type {boolean}
   * @memberof Notification
   */
  is_read: boolean;
}

/**
 * @export
 * @namespace Notification
 */
export namespace Notification {
  /**
   * @export
   * @enum {string}
   */
  export enum NotifTypeEnum {
    System = <any>'system',
    Default = <any>'default',
  }
}
/**
 *
 * @export
 * @interface Office
 */
export interface Office {
  /**
   * Физический адрес офиса
   * @type {string}
   * @memberof Office
   */
  address: string;
  /**
   * URL для просмотра адреса на карте
   * @type {string}
   * @memberof Office
   */
  map_url: string;
}
/**
 * ОТТ (Онлайн ТВ iPakt)
 * @export
 * @interface OttGroup
 */
export interface OttGroup {
  /**
   * Название услуги
   * @type {string}
   * @memberof OttGroup
   */
  service_title?: string;
  /**
   * Список услуг ОТТ
   * @type {Array<ServiceDtvOtt>}
   * @memberof OttGroup
   */
  services?: Array<ServiceDtvOtt>;
}
/**
 * Дополнительные пакеты ЦТВ/ОТТ
 * @export
 * @interface PacketDtvOtt
 */
export interface PacketDtvOtt {
  /**
   * Имя пакета
   * @type {string}
   * @memberof PacketDtvOtt
   */
  packet_name: string;
  /**
   * Идентификатор пакета в системе
   * @type {string}
   * @memberof PacketDtvOtt
   */
  key: string;
  /**
   * Подключен ли пакет
   * @type {boolean}
   * @memberof PacketDtvOtt
   */
  packet_set: boolean;
  /**
   * ID тарифа
   * @type {number}
   * @memberof PacketDtvOtt
   */
  plan_id: number;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof PacketDtvOtt
   */
  plan_name: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof PacketDtvOtt
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof PacketDtvOtt
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof PacketDtvOtt
   */
  fee_kind: FeeKindEnum;
  /**
   * Статус особого предложения (огонек)             - только при редактировании пакетов
   * @type {boolean}
   * @memberof PacketDtvOtt
   */
  is_highlighted: boolean;
  /**
   * Можно ли выбирать пакет
   * @type {boolean}
   * @memberof PacketDtvOtt
   */
  can_change_packet: boolean;
  /**
   * Список объединенных пакетов
   * @type {Array<string>}
   * @memberof PacketDtvOtt
   */
  packet_union?: Array<string>;
}

/**
 * @export
 * @namespace PacketDtvOtt
 */

export enum FeeKindEnum {
  Day = <any>'day',
  Month = <any>'month',
  Year = <any>'year',
}
export namespace PacketDtvOtt {
  /**
   * @export
   * @enum {string}
   */
}
/**
 * Пакет ОТТ/ЦТВ
 * @export
 * @interface PacketTVRequest
 */
export interface PacketTVRequest {
  /**
   * Идентификатор пакета в системе
   * @type {string}
   * @memberof PacketTVRequest
   */
  key: string;
  /**
   * ID тарифного плана
   * @type {number}
   * @memberof PacketTVRequest
   */
  plan_id: number;
}
/**
 * Список пакетов для подключения
 * @export
 * @interface PacketsRequest
 */
export interface PacketsRequest {
  /**
   *
   * @type {Array<PacketTVRequest>}
   * @memberof PacketsRequest
   */
  packets: Array<PacketTVRequest>;
}
/**
 *
 * @export
 * @interface Payment
 */
export interface Payment {
  /**
   * Дата платежа DD.MM.YYYY
   * @type {string}
   * @memberof Payment
   */
  date: string;
  /**
   * Название категории платежа
   * @type {string}
   * @memberof Payment
   */
  name: string;
  /**
   * Сумма платежа
   * @type {number}
   * @memberof Payment
   */
  amount: number;
}
/**
 * Получение данных для отправки заявления об ошибочном платеже
 * @export
 * @interface PaymentError
 */
export interface PaymentError {
  /**
   * Адрес подключения
   * @type {string}
   * @memberof PaymentError
   */
  connection_address: string;
  /**
   * ФИО
   * @type {string}
   * @memberof PaymentError
   */
  full_name?: string;
  /**
   * Номер договора
   * @type {number}
   * @memberof PaymentError
   */
  agreement: number;
  /**
   * Номер телефона
   * @type {string}
   * @memberof PaymentError
   */
  contact_phone: string;
  /**
   * Список платежных систем
   * @type {Array<PaymentGate>}
   * @memberof PaymentError
   */
  payment_gate: Array<PaymentGate>;
  /**
   * Условия подачи заявления
   */
  submission_conditions: {
    title: string
    text: string
  }
  /**
   * Список заявлений шаблонов
   */
  templates: null | Array<StatementTemplate>
  /**
   * Описание для файлов
   */
  statement: {
    title: string
    description: string
    required: boolean
    max_files: number
    attachment_instructions: string
  }
  payment_receipts: {
    title: string
    description: string
    required: boolean
    max_files: number
    attachment_instructions: string
  }
}
/**
 * Отправка заявления об ошибочном платеже
 * @export
 * @interface PaymentErrorRequestRequest
 */
export interface PaymentErrorRequestRequest {
  /**
   * Адрес подключения
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  connection_address: string;
  /**
   * ФИО
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  full_name?: string;
  /**
   * Номер телефона
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  contact_phone: string;
  /**
   * Дата оплаты
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  payment_date: string;
  /**
   * Платежная система
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  payment_gate_name: string;
  /**
   * Номер ошибочного договора
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  error_agreement: string;
  /**
   * Сумма платежа
   * @type {string}
   * @memberof PaymentErrorRequestRequest
   */
  amount: string;
  /**
   * Рукописное заявление
   * @type {Blob}
   * @memberof PaymentErrorRequestRequest
   */
  file?: string | undefined;
}
/**
 * Тип платежной системы
 * @export
 * @interface PaymentGate
 */
export interface PaymentGate {
  /**
   * Имя платежного шлюза
   * @type {string}
   * @memberof PaymentGate
   */
  name: string;
}

export interface StatementTemplate {
  link: string
  title: string
}

/**
 *
 * @export
 * @interface PaymentsInfo
 */
export interface PaymentsInfo {
  /**
   * Итоговая сумма платежей
   * @type {number}
   * @memberof PaymentsInfo
   */
  total_amount: number;
  /**
   * Список платежей
   * @type {Array<Payment>}
   * @memberof PaymentsInfo
   */
  payments?: Array<Payment>;
}
/**
 * Номер телефона клиента
 * @export
 * @interface Phone
 */
export interface Phone {
  /**
   * Номер телефона
   * @type {string}
   * @memberof Phone
   */
  phone_number: string;
  /**
   * Тип номера - основной / дополнительный  * `1` - Основной номер * `0` - Дополнительный номер
   * @type {number}
   * @memberof Phone
   */
  phone_type: PhoneTypeEnum;
  /**
   * Запрет / разрешение на изменение телефона
   * @type {boolean}
   * @memberof Phone
   */
  is_change_phone: boolean;
}

/**
 * @export
 * @namespace Phone
 */
export enum PhoneTypeEnum {
  NUMBER_1 = <any>1,
  NUMBER_0 = <any>0,
}

/**
 *
 * @export
 * @interface PhoneCall
 */
export interface PhoneCall {
  /**
   * Дата звонка DD.MM.YYYY
   * @type {string}
   * @memberof PhoneCall
   */
  date: string;
  /**
   * Вызывающий номер
   * @type {string}
   * @memberof PhoneCall
   */
  source: string;
  /**
   * Вызываемый номер
   * @type {string}
   * @memberof PhoneCall
   */
  destination: string;
  /**
   * Длительность звонка в минутах
   * @type {number}
   * @memberof PhoneCall
   */
  minutes: number;
  /**
   * Стоимость звонка
   * @type {number}
   * @memberof PhoneCall
   */
  cost: number;
  /**
   * Код населенного пункта
   * @type {string}
   * @memberof PhoneCall
   */
  prefix: string;
}
/**
 *
 * @export
 * @interface PhoneCallInfo
 */
export interface PhoneCallInfo {
  /**
   * Номер телефона для которого выводится список
   * @type {string}
   * @memberof PhoneCallInfo
   */
  number_phone: string;
  /**
   * Общая стоимость всех звонков
   * @type {number}
   * @memberof PhoneCallInfo
   */
  total_amount: number;
  /**
   * Список звонков
   * @type {Array<PhoneCall>}
   * @memberof PhoneCallInfo
   */
  calls?: Array<PhoneCall>;
}
/**
 *
 * @export
 * @interface PhoneCallOverview
 */
export interface PhoneCallOverview {
  /**
   * Номер договора
   * @type {number}
   * @memberof PhoneCallOverview
   */
  agreement: number;
  /**
   * Начальная дата DD.MM.YYYY
   * @type {string}
   * @memberof PhoneCallOverview
   */
  start_date: string;
  /**
   * Конечная дата DD.MM.YYYY
   * @type {string}
   * @memberof PhoneCallOverview
   */
  end_date: string;
  /**
   * Общая сумма по всем звонкам за период
   * @type {number}
   * @memberof PhoneCallOverview
   */
  balance: number;
  /**
   * Список звонков, сгруппированных по номерам телефонов
   * @type {Array<PhoneCallInfo>}
   * @memberof PhoneCallOverview
   */
  calls?: Array<PhoneCallInfo>;
}
/**
 *
 * @export
 * @interface PhoneRequest
 */
export interface PhoneRequest {
  /**
   * Номер телефона
   * @type {string}
   * @memberof PhoneRequest
   */
  phone: string;
}
/**
 *
 * @export
 * @interface PhoneUpdateRequest
 */
export interface PhoneUpdateRequest {
  /**
   * Старый номер телефона
   * @type {string}
   * @memberof PhoneUpdateRequest
   */
  old_phone: string;
  /**
   * Новый номер телефона
   * @type {string}
   * @memberof PhoneUpdateRequest
   */
  new_phone: string;
}
/**
 * Тарифный план услуги
 * @export
 * @interface Plan
 */
export interface Plan {
  /**
   * ID тарифа
   * @type {number}
   * @memberof Plan
   */
  plan_id: number;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof Plan
   */
  plan_name: string;
  /**
   * Описание тарифа
   * @type {string}
   * @memberof Plan
   */
  plan_descr: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof Plan
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof Plan
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof Plan
   */
  fee_kind: FeeKindEnum;
  /**
   * Скорость тарифа (Мбит/с). Только для услуги Интернет
   * @type {number}
   * @memberof Plan
   */
  speed?: number;
  /**
   * Является ли этот тариф абонементом
   * @type {boolean}
   * @memberof Plan
   */
  is_ab: boolean;
  /**
   * Дата подключения тарифного плана
   * @type {string}
   * @memberof Plan
   */
  start_date?: string;
  /**
   * Дата окончания абонемента
   * @type {string}
   * @memberof Plan
   */
  end_date?: string;
}

/**
 * @export
 * @namespace Plan
 */

/**
 *
 * @export
 * @interface PlanHistory
 */
export interface PlanHistory {
  /**
   * Дата подключения
   * @type {string}
   * @memberof PlanHistory
   */
  connection_date: string;
  /**
   * Тип услуги
   * @type {string}
   * @memberof PlanHistory
   */
  service_type: string;
  /**
   * Тариф
   * @type {string}
   * @memberof PlanHistory
   */
  plan: string;
  /**
   * Дата смены тарифов
   * @type {string}
   * @memberof PlanHistory
   */
  plan_change_date: string;
}
/**
 * Доступный тариф для подключения как новая услуга
 * @export
 * @interface PlanItem
 */
export interface PlanItem {
  details_url: {
    text_link: string;
    url: string;
  } | null;

  isBlock: boolean;
  /**
   * ID тарифа
   * @type {number}
   * @memberof PlanItem
   */
  plan_id: number;
  key?: string;
  channels_key?: string;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof PlanItem
   */
  plan_name: string;
  /**
   * Описание тарифа
   * @type {string}
   * @memberof PlanItem
   */
  plan_descr: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof PlanItem
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof PlanItem
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof PlanItem
   */
  fee_kind: FeeKindEnum;
  /**
   * Скорость тарифа (Мбит/с). Только для услуги Интернет
   * @type {number}
   * @memberof PlanItem
   */
  speed?: number;
  /**
   * Является ли этот тариф абонементом
   * @type {boolean}
   * @memberof PlanItem
   */
  is_ab: boolean;
  /**
   * Порядковый номер выведения тарифа
   * @type {number}
   * @memberof PlanItem
   */
  display_order: number;
  /**
   * Статус выделения 'огонька' (горячее предложение)
   * @type {boolean}
   * @memberof PlanItem
   */
  is_highlighted: boolean;
  /**
   * Дополнительный текст только для Телефонии
   * @type {string}
   * @memberof PlanItem
   */
  description?: string;
  /**
   * Список условий тарифа только для Телефонии
   * @type {Array<TariffConditionTel>}
   * @memberof PlanItem
   */
  tariff_conditions: Array<TariffConditionTel>;
  /**
   * Тултип для Абонемента только для Телевидения
   * @type {string}
   * @memberof PlanItem
   */
  tooltip?: string;
  /**
   * Дополнительный блок информации только для Телевидения
   * @type {InfoTvAbonement}
   * @memberof PlanItem
   */
  additional_info?: InfoTvAbonement;
  abonements: PlanAbonementInternet[];
}

/**
 * @export
 * @namespace PlanItem
 */

export interface PlanAbonementInternet {
  plan_id: number;
  plan_name: string | null;
  plan_descr: string | null;
  plan_fee: number | null;
  fee_period: number | null;
  fee_kind: FeeKindEnum;
  speed: string | null;
  is_ab: boolean;
  display_order: number;
  is_highlighted: boolean;
  description: string | null;
  tooltip: string | null;
  plan_fee_month: string | number | null;
  abonement_full_name: string;
}

export namespace PlanItem {
  /**
   * @export
   * @enum {string}
   */
  export enum FeeKindEnum {
    Day = <any>'day',
    Month = <any>'month',
    Year = <any>'year',
    Null = <any>'null',
  }
}
/**
 *
 * @export
 * @interface PlansChangeList
 */
export interface PlansChangeList {
  /**
   * История смены тарифов
   * @type {Array<PlanHistory>}
   * @memberof PlansChangeList
   */
  plan_changes: Array<PlanHistory>;
}
/**
 * Профиль клиента
 * @export
 * @interface IProfile
 */
export interface IProfile {
  /**
   * Нижний блок ( только для телефона)
   * @type {string}
   * @memberof IProfile
   */

  announces: Announce[];
  subs_text: string;
  dop_info_description: string;
  building_name_full: string | null;
  /**
   * Запрет / разрешение на изменение пароля
   * @type {boolean}
   * @memberof IProfile
   */
  is_change_password: boolean;
  /**
   * Запрет / разрешение на изменение подписок
   * @type {boolean}
   * @memberof IProfile
   */
  is_change_subs: boolean;
  /**
   * Внутренний идентификатор адреса
   * @type {number}
   * @memberof IProfile
   */
  building_id: number;
  /**
   * Адрес (дом)
   * @type {string}
   * @memberof IProfile
   */
  building_name: string;
  /**
   * Номер квартиры
   * @type {string}
   * @memberof IProfile
   */
  flat: string;
  /**
   * Номер комнаты
   * @type {string}
   * @memberof IProfile
   */
  apart: string;
  /**
   * ФИО
   * @type {string}
   * @memberof IProfile
   */
  fio: string;
  /**
   * Дата рождения
   * @type {string}
   * @memberof IProfile
   */
  birth_date: string;
  /**
   * Номер договора
   * @type {number}
   * @memberof IProfile
   */
  agreement: number;
  /**
   * Электронная почта
   * @type {Email}
   * @memberof IProfile
   */
  email: Email;
  /**
   * Список телефонов
   * @type {Array<Phone>}
   * @memberof IProfile
   */
  phones: {
    base_phones: Phone[];
    additional_phones: Phone[];
    base_phones_notice: string | null;
  };

  /**
   * Список доступных подписок
   * @type {Array<Subscription>}
   * @memberof IProfile
   */
  subscriptions: Array<Subscription>;
  /**
   * Последняя дата изменения пароля
   * @type {string}
   * @memberof IProfile
   */
  last_date_change_pswd: string;
}
/**
 * Дополнительные (платные) модули
 * @export
 * @interface RecAdditionalModule
 */
export interface RecAdditionalModule {
  /**
   * Имя модуля
   * @type {string}
   * @memberof RecAdditionalModule
   */
  module_name: string;
  /**
   * Описание модуля
   * @type {string}
   * @memberof RecAdditionalModule
   */
  module_description: string;
  /**
   * Идентификатор пакета в системе
   * @type {string}
   * @memberof RecAdditionalModule
   */
  key: string;
  /**
   * Подключен ли модуль у клиента
   * @type {boolean}
   * @memberof RecAdditionalModule
   */
  module_set?: boolean;
  /**
   * ID тарифа
   * @type {number}
   * @memberof RecAdditionalModule
   */
  plan_id: number;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof RecAdditionalModule
   */
  plan_name: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof RecAdditionalModule
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof RecAdditionalModule
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof RecAdditionalModule
   */
  fee_kind: RecAdditionalModule.FeeKindEnum;
}

/**
 * @export
 * @namespace RecAdditionalModule
 */
export namespace RecAdditionalModule {
  /**
   * @export
   * @enum {string}
   */
  export enum FeeKindEnum {
    Day = <any>'day',
    Month = <any>'month',
    Year = <any>'year',
  }
}
/**
 *
 * @export
 * @interface RecBaseModule
 */
export interface RecBaseModule {
  /**
   * Наименование базового модуля
   * @type {string}
   * @memberof RecBaseModule
   */
  module_name: string;
  /**
   * Статус базового модуля
   * @type {string}
   * @memberof RecBaseModule
   */
  module_status?: string;
}
/**
 * Базовый модуль для услуги Видеонаблюдения
 * @export
 * @interface RecBaseModules
 */
export interface RecBaseModules {
  /**
   * Дополнительная информация для основных модулей
   * @type {string}
   * @memberof RecBaseModules
   */
  description?: string;
  /**
   * Модули подключенные по умолчанию
   * @type {Array<RecBaseModule>}
   * @memberof RecBaseModules
   */
  modules?: Array<RecBaseModule>;
}
/**
 * Информация об аренде оборудовния (дополнительное оборудование)
 * @export
 * @interface RentDopEquipments
 */
export interface RentDopEquipments {
  /**
   * Уникальный id услуги
   * @type {string}
   * @memberof RentDopEquipments
   */
  rent_id: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof RentDopEquipments
   */
  service_type: ServiceTypeEnum;
  /**
   * Уникальный id услуги к которой присоеденена аренда
   * @type {string}
   * @memberof RentDopEquipments
   */
  service_cid: string;
  /**
   *
   * @type {Plan}
   * @memberof RentDopEquipments
   */
  plan: Plan;
  /**
   * Дата начала аренды
   * @type {string}
   * @memberof RentDopEquipments
   */
  rent_start_date: string;
  /**
   * Дата окончания аренды
   * @type {string}
   * @memberof RentDopEquipments
   */
  rent_end_date: string;
  /**
   * Условия предоставления услуги
   * @type {string}
   * @memberof RentDopEquipments
   */
  rent_terms: string;
}

/**
 * @export
 * @namespace RentDopEquipments
 */

/**
 *
 * @export
 * @interface ReportsOverview
 */
export interface ReportsOverview {
  /**
   * Номер договора
   * @type {number}
   * @memberof ReportsOverview
   */
  agreement: number;
  /**
   * Начальная дата DD.MM.YYYY
   * @type {string}
   * @memberof ReportsOverview
   */
  start_date: string;
  /**
   * Конечная дата DD.MM.YYYY
   * @type {string}
   * @memberof ReportsOverview
   */
  end_date: string;
  /**
   * Общая сумма по всем начислениям
   * @type {number}
   * @memberof ReportsOverview
   */
  balance: number;
  /**
   * Показывать ли кнопку Детализация звонков
   * @type {boolean}
   * @memberof ReportsOverview
   */
  show_btn_detail_phone: boolean;
  /**
   * Список начислений
   * @type {ChargeInfo}
   * @memberof ReportsOverview
   */
  charges?: ChargeInfo;
  /**
   * Список платежей
   * @type {PaymentsInfo}
   * @memberof ReportsOverview
   */
  payments?: PaymentsInfo;
}
/**
 *
 * @export
 * @interface ResponseAutopayment
 */
export interface ResponseAutopayment {
  /**
   *
   * @type {Autopayment}
   * @memberof ResponseAutopayment
   */
  data: Autopayment;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseAutopayment
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseAutopayment
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseAutopayment
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseAvailablePlans
 */

export interface ResponseAvailablePlansForChange {
  /**
   *
   * @type {AvailablePlans}
   * @memberof ResponseAvailablePlans
   */
  data: AvailablePlansForChange;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseAvailablePlans
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseAvailablePlans
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseAvailablePlans
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseAvailableServicesList
 */
export interface ResponseAvailableServicesList {
  /**
   *
   * @type {AvailableServicesList}
   * @memberof ResponseAvailableServicesList
   */
  data: AvailableServicesList;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseAvailableServicesList
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseAvailableServicesList
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseAvailableServicesList
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseBilling
 */
export interface ResponseBilling {
  /**
   *
   * @type {Billing}
   * @memberof ResponseBilling
   */
  data: Billing;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseBilling
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseBilling
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseBilling
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseBlockServiceGet
 */
export interface ResponseBlockServiceGet {
  /**
   *
   * @type {BlockServiceGet}
   * @memberof ResponseBlockServiceGet
   */
  data: BlockServiceGet;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseBlockServiceGet
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseBlockServiceGet
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseBlockServiceGet
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseChangeExtip
 */
export interface ResponseChangeExtip {
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseChangeExtip
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseChangeExtip
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseChangeExtip
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseChannels
 */
export interface ResponseChannels {
  /**
   *
   * @type {Channels}
   * @memberof ResponseChannels
   */
  data: Channels;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseChannels
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseChannels
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseChannels
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseClient
 */
export interface ResponseClient {
  /**
   *
   * @type {Client}
   * @memberof ResponseClient
   */
  data: Client;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseClient
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseClient
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseClient
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseContactsResponse
 */
export interface ResponseContactsResponse {
  /**
   *
   * @type {ContactsResponse}
   * @memberof ResponseContactsResponse
   */
  data: ContactsResponse;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseContactsResponse
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseContactsResponse
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseContactsResponse
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseCredit
 */
export interface ResponseCredit {
  /**
   *
   * @type {Credit}
   * @memberof ResponseCredit
   */
  data: Credit;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseCredit
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseCredit
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseCredit
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseDocsOneTypeList
 */
export interface ResponseDocsOneTypeList {
  /**
   *
   * @type {DocsOneTypeList}
   * @memberof ResponseDocsOneTypeList
   */
  data: DocsOneTypeList;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseDocsOneTypeList
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseDocsOneTypeList
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseDocsOneTypeList
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseDocumentList
 */
export interface ResponseDocumentList {
  /**
   *
   * @type {DocumentList}
   * @memberof ResponseDocumentList
   */
  data: DocumentList;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseDocumentList
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseDocumentList
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseDocumentList
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseFileDownload
 */
export interface ResponseFileDownload {
  /**
   *
   * @type {FileDownload}
   * @memberof ResponseFileDownload
   */
  data: FileDownload;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseFileDownload
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseFileDownload
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseFileDownload
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseMainAvailablePlans
 */

export interface ResponseMainAvailablePlans {
  /**
   *
   * @type {MainAvailablePlans}
   * @memberof ResponseMainAvailablePlans
   */
  data: MainAvailablePlans;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseMainAvailablePlans
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseMainAvailablePlans
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseMainAvailablePlans
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponsePaymentError
 */
export interface ResponsePaymentError {
  /**
   *
   * @type {PaymentError}
   * @memberof ResponsePaymentError
   */
  data: PaymentError;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponsePaymentError
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponsePaymentError
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponsePaymentError
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponsePhoneCallOverview
 */
export interface ResponsePhoneCallOverview {
  /**
   *
   * @type {PhoneCallOverview}
   * @memberof ResponsePhoneCallOverview
   */
  data: PhoneCallOverview;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponsePhoneCallOverview
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponsePhoneCallOverview
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponsePhoneCallOverview
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponsePlansChangeList
 */
export interface ResponsePlansChangeList {
  /**
   *
   * @type {PlansChangeList}
   * @memberof ResponsePlansChangeList
   */
  data: PlansChangeList;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponsePlansChangeList
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponsePlansChangeList
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponsePlansChangeList
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseProfile
 */
export interface ResponseProfile {
  /**
   *
   * @type {IProfile}
   * @memberof ResponseProfile
   */
  data: IProfile;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseProfile
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseProfile
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseProfile
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseReportsOverview
 */
export interface ResponseReportsOverview {
  /**
   *
   * @type {ReportsOverview}
   * @memberof ResponseReportsOverview
   */
  data: ReportsOverview;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseReportsOverview
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseReportsOverview
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseReportsOverview
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceCollectiveDomofon
 */
export interface ResponseServiceCollectiveDomofon {
  /**
   *
   * @type {ServiceCollectiveDomofon}
   * @memberof ResponseServiceCollectiveDomofon
   */
  data: ServiceCollectiveDomofon;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceCollectiveDomofon
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceCollectiveDomofon
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceCollectiveDomofon
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceCollectiveRadio
 */
export interface ResponseServiceCollectiveRadio {
  /**
   *
   * @type {ServiceCollectiveRadio}
   * @memberof ResponseServiceCollectiveRadio
   */
  data: ServiceCollectiveRadio;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceCollectiveRadio
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceCollectiveRadio
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceCollectiveRadio
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceCondition
 */
export interface ResponseServiceCondition {
  /**
   *
   * @type {ServiceCondition}
   * @memberof ResponseServiceCondition
   */
  data: ServiceCondition;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceCondition
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceCondition
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceCondition
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceDocsis
 */
export interface ResponseServiceDocsis {
  /**
   *
   * @type {ServiceDocsis}
   * @memberof ResponseServiceDocsis
   */
  data: ServiceDocsis;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceDocsis
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceDocsis
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceDocsis
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceEthernet
 */
export interface ResponseServiceEthernet {
  /**
   *
   * @type {ServiceEthernet}
   * @memberof ResponseServiceEthernet
   */
  data: ServiceEthernet;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceEthernet
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceEthernet
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceEthernet
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceExtIp
 */
export interface ResponseServiceExtIp {
  /**
   *
   * @type {ServiceExtIp}
   * @memberof ResponseServiceExtIp
   */
  data: ServiceExtIp;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceExtIp
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceExtIp
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceExtIp
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServicePon
 */
export interface ResponseServicePon {
  /**
   *
   * @type {ServicePon}
   * @memberof ResponseServicePon
   */
  data: ServicePon;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServicePon
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServicePon
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServicePon
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceRent
 */
export interface ResponseServiceRent {
  /**
   *
   * @type {ServiceRent}
   * @memberof ResponseServiceRent
   */
  data: ServiceRent;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceRent
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceRent
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceRent
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceTel
 */
export interface ResponseServiceTel {
  /**
   *
   * @type {ServiceTel}
   * @memberof ResponseServiceTel
   */
  data: ServiceTel;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceTel
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceTel
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceTel
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceTv
 */
export interface ResponseServiceTv {
  /**
   *
   * @type {ServiceTv}
   * @memberof ResponseServiceTv
   */
  data: ServiceTv;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceTv
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceTv
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceTv
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServiceUDomofon
 */
export interface ResponseServiceUDomofon {
  /**
   *
   * @type {ServiceUDomofon}
   * @memberof ResponseServiceUDomofon
   */
  data: ServiceUDomofon;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceUDomofon
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceUDomofon
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceUDomofon
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServicesClient
 */
export interface ResponseServicesClient {
  /**
   *
   * @type {ServicesClient}
   * @memberof ResponseServicesClient
   */
  data: ServicesClient;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServicesClient
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServicesClient
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServicesClient
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseServicesRec
 */
export interface ResponseServicesRec {
  /**
   *
   * @type {ServicesRec}
   * @memberof ResponseServicesRec
   */
  data: ServicesRec;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServicesRec
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServicesRec
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServicesRec
   */
  client_message: string;
}
/**
 *
 * @export
 * @interface ResponseSubscriptionManagement
 */
export interface ResponseSubscriptionManagement {
  /**
   *
   * @type {SubscriptionManagement}
   * @memberof ResponseSubscriptionManagement
   */
  data: SubscriptionManagement;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseSubscriptionManagement
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseSubscriptionManagement
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseSubscriptionManagement
   */
  client_message: string;
}
/**
 * Запланированные изменения
 * @export
 * @interface Schedule
 */
export interface Schedule {
  /**
   * Наименование раздела на услуге
   * @type {string}
   * @memberof Schedule
   */
  title: string;
  /**
   *
   * @type {Array<SchedulerPlan>}
   * @memberof Schedule
   */
  schedules?: Array<SchedulerPlan>;
}
/**
 * Планировщик на услуге
 * @export
 * @interface SchedulerPlan
 */
export interface SchedulerPlan {
  /**
   * ID задачи на изменение
   * @type {number}
   * @memberof SchedulerPlan
   */
  id_shed: number;
  /**
   * Имя запланированного действия
   * @type {string}
   * @memberof SchedulerPlan
   */
  name_task: string;
  /**
   * Дата начала изменения
   * @type {string}
   * @memberof SchedulerPlan
   */
  start_date: string;
  /**
   * Кто создал задачу
   * @type {string}
   * @memberof SchedulerPlan
   */
  created_by: string;
  /**
   * Статус задачи  * `planned` - Запланирована * `completed` - Выполнена * `canceled` - Отменена
   * @type {string}
   * @memberof SchedulerPlan
   */
  task_status?: TaskStatusEnum;
}

/**
 * @export
 * @namespace SchedulerPlan
 */

export enum TaskStatusEnum {
  Planned = 'planned',
  Completed = 'completed',
  Canceled = 'canceled',
}

/**
 * Услуга Телевидение (АТВ)
 * @export
 * @interface ServiceAtv
 */
export interface ServiceAtv {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceAtv
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceAtv
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceAtv
   */
  show_unblock_btn: boolean;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceAtv
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceAtv
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceAtv
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceAtv
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceAtv
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceAtv
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceAtv
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceAtv
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceAtv
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceAtv
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceAtv
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceAtv
   */
  available_options: MainAvailablePlans;
  /**
   * Дополнительные кнопки для услуги АТВ
   * @type {Array<AdditionalButtonAtv>}
   * @memberof ServiceAtv
   */
  additional_buttons: Array<AdditionalButtonAtv>;
  /**
   * Показывать ли кнопку Опции
   * @type {boolean}
   * @memberof ServiceAtv
   */
  show_dop_options: boolean;
}

/**
 * @export
 * @namespace ServiceAtv
 */

/**
 * Информация о приостановке услуги
 * @export
 * @interface ServiceBlockInfo
 */
export interface ServiceBlockInfo {
  /**
   * Название
   * @type {string}
   * @memberof ServiceBlockInfo
   */
  title: string;
  /**
   * Дата начала блокировки
   * @type {string}
   * @memberof ServiceBlockInfo
   */
  block_start_date: string;
  /**
   * Дата окончания блокировки
   * @type {string}
   * @memberof ServiceBlockInfo
   */
  block_end_date: string;
  /**
   * Кто включил блокировку
   * @type {string}
   * @memberof ServiceBlockInfo
   */
  who_block: string;
  /**
   * Запланирована ли блокировка
   * @type {boolean}
   * @memberof ServiceBlockInfo
   */
  status: string;
}
/**
 * Услуга Телевидение (Коллективное Атв)
 * @export
 * @interface ServiceCollectiveAtv
 */
export interface ServiceCollectiveAtv {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceCollectiveAtv
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceCollectiveAtv
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceCollectiveAtv
   */
  show_unblock_btn: boolean;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceCollectiveAtv
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceCollectiveAtv
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceCollectiveAtv
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceCollectiveAtv
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceCollectiveAtv
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceCollectiveAtv
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceCollectiveAtv
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceCollectiveAtv
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceCollectiveAtv
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceCollectiveAtv
   */
  plan?: Plan;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceCollectiveAtv
   */
  available_options: MainAvailablePlans;
  /**
   * Дополнительные кнопки для услуги Коллективное Телевидение
   * @type {Array<AdditionalButtonAtv>}
   * @memberof ServiceCollectiveAtv
   */
  additional_buttons: Array<AdditionalButtonAtv>;
}

/**
 * @export
 * @namespace ServiceCollectiveAtv
 */

/**
 * Услуга Домофон (Коллективная услуга)
 * @export
 * @interface ServiceCollectiveDomofon
 */
export interface ServiceCollectiveDomofon {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceCollectiveDomofon
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceCollectiveDomofon
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceCollectiveDomofon
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceCollectiveDomofon
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceCollectiveDomofon
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceCollectiveDomofon
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceCollectiveDomofon
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceCollectiveDomofon
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceCollectiveDomofon
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceCollectiveDomofon
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceCollectiveDomofon
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceCollectiveDomofon
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceCollectiveDomofon
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceCollectiveDomofon
   */
  plan?: Plan;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceCollectiveDomofon
   */
  available_options: MainAvailablePlans;
}

/**
 * @export
 * @namespace ServiceCollectiveDomofon
 */

/**
 * Услуга Радио (Коллективная услуга)
 * @export
 * @interface ServiceCollectiveRadio
 */
export interface ServiceCollectiveRadio {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceCollectiveRadio
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceCollectiveRadio
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceCollectiveRadio
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceCollectiveRadio
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceCollectiveRadio
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceCollectiveRadio
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceCollectiveRadio
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceCollectiveRadio
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceCollectiveRadio
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceCollectiveRadio
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceCollectiveRadio
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceCollectiveRadio
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceCollectiveRadio
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceCollectiveRadio
   */
  plan?: Plan;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceCollectiveRadio
   */
  available_options: MainAvailablePlans;
}

/**
 * @export
 * @namespace ServiceCollectiveRadio
 */

/**
 *
 * @export
 * @interface ServiceCondition
 */
export interface ServiceCondition {
  /**
   *
   * @type {string}
   * @memberof ServiceCondition
   */
  code_type: string;
  /**
   *
   * @type {string}
   * @memberof ServiceCondition
   */
  text: string;
  /**
   * * `plain` - Plain Text * `html` - HTML
   * @type {string}
   * @memberof ServiceCondition
   */
  text_type: ServiceCondition.TextTypeEnum;
}

/**
 * @export
 * @namespace ServiceCondition
 */
export namespace ServiceCondition {
  /**
   * @export
   * @enum {string}
   */
  export enum TextTypeEnum {
    Plain = <any>'plain',
    Html = <any>'html',
  }
}
/**
 * Услуга Интернет (Docsis)
 * @export
 * @interface ServiceDocsis
 */
export interface ServiceDocsis {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceDocsis
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceDocsis
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceDocsis
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceDocsis
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceDocsis
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceDocsis
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceDocsis
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceDocsis
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceDocsis
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceDocsis
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceDocsis
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceDocsis
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceDocsis
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceDocsis
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceDocsis
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceDocsis
   */
  available_options: MainAvailablePlans;
  /**
   *
   * @type {ServiceExtIp}
   * @memberof ServiceDocsis
   */
  external_ip?: ServiceExtIp;
  /**
   * Мак адрес модема
   * @type {string}
   * @memberof ServiceDocsis
   */
  modem_device: string;
  /**
   * IP модема
   * @type {string}
   * @memberof ServiceDocsis
   */
  modem_ip: string;
  /**
   * IP клиента
   * @type {string}
   * @memberof ServiceDocsis
   */
  client_ip: string;
  /**
   * Список дополнительного оборудования
   * @type {Equipment}
   * @memberof ServiceDocsis
   */
  equipment: Equipment;
}

/**
 * @export
 * @namespace ServiceDocsis
 */
/**
 * @export
 * @enum {string}
 */

/**
 * @export
 * @enum {string}
 */

/**
 * Услуга Телевидение (ЦТВ)
 * @export
 * @interface ServiceDtvOtt
 */
export interface ServiceDtvOtt {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceDtvOtt
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceDtvOtt
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceDtvOtt
   */
  show_unblock_btn: boolean;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceDtvOtt
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceDtvOtt
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceDtvOtt
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceDtvOtt
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceDtvOtt
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceDtvOtt
   */
  available_options: MainAvailablePlans;
  /**
   * Имя модуля для услуги Цифровое телевидение (ЦТВ)
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  dtv_module_name: string;
  /**
   * Показывать ли кнопку Опции
   * @type {boolean}
   * @memberof ServiceDtvOtt
   */
  show_dop_options: boolean;
  /**
   * Показывать ли кнопку Редактировать (модули/опции)
   * @type {boolean}
   * @memberof ServiceDtvOtt
   */
  show_options_change_btn: boolean;
  /**
   * Список ЦТВ пакетов - подключенных и доступных
   * @type {Array<PacketDtvOtt>}
   * @memberof ServiceDtvOtt
   */
  packets: Array<PacketDtvOtt>;
  /**
   * Список дополнительного оборудования
   * @type {Equipment}
   * @memberof ServiceDtvOtt
   */
  equipment: Equipment;
  /**
   * Код страницы условий для подключения пакетов ЦТВ
   * @type {string}
   * @memberof ServiceDtvOtt
   */
  condition_block: Condition | null;
}

/**
 * @export
 * @namespace ServiceDtvOtt
 */

/**
 * Услуга Интернет (Ethernet)
 * @export
 * @interface ServiceEthernet
 */
export interface ServiceEthernet {
  additional_buttons: Array<AdditionalButtonAtv>;

  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceEthernet
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceEthernet
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceEthernet
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceEthernet
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceEthernet
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceEthernet
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceEthernet
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceEthernet
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceEthernet
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceEthernet
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceEthernet
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceEthernet
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceEthernet
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceEthernet
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceEthernet
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceEthernet
   */
  available_options: MainAvailablePlans;
  /**
   *
   * @type {ServiceExtIp}
   * @memberof ServiceEthernet
   */
  external_ip?: ServiceExtIp;
  /**
   * Внутренний IP клиента
   * @type {string}
   * @memberof ServiceEthernet
   */
  client_ip: string;
  /**
   * Список дополнительного оборудования
   * @type {Equipment}
   * @memberof ServiceEthernet
   */
  equipment: Equipment;
}

/**
 * @export
 * @namespace ServiceEthernet
 */

/**
 * Дополнительная опция Внешний IP-адрес
 * @export
 * @interface ServiceExtIp
 */
export interface ServiceExtIp {
  ext_ip_id: number;
  /**
   * Показывать ли кнопку Изменить Внешний ip
   * @type {boolean}
   * @memberof ServiceExtIp
   */
  show_delete_btn: boolean;
  show_change_btn: boolean;
  /**
   * Уникальный ID основной услуги
   * @type {string}
   * @memberof ServiceExtIp
   */
  service_cid: string;
  /**
   * IP-адрес
   * @type {string}
   * @memberof ServiceExtIp
   */
  ext_ip: string;
  /**
   * Тарифный план услуги
   * @type {Plan}
   * @memberof ServiceExtIp
   */
  plan: Plan;
}
/**
 * Данные для подключения новой услуги
 * @export
 * @interface ServiceNewRequest
 */
export interface ServiceNewRequest {
  /**
   *
   * @type {string}
   * @memberof ServiceNewRequest
   */
  fio: string;
  /**
   * * `internet` - Интернет * `tv` - Телевидение * `online_tv` - ОнлайнТВ iPakt * `intercom` - Домофон * `cctv` - Видеонаблюдение * `equipment_rent` - Аренда оборудования * `digital_tv` - Цифровое телевидение * `telephony` - Телефония
   * @type {string}
   * @memberof ServiceNewRequest
   */
  service_type: ServiceTypeEnum;
  /**
   *
   * @type {string}
   * @memberof ServiceNewRequest
   */
  building: string;
  /**
   *
   * @type {string}
   * @memberof ServiceNewRequest
   */
  contact_phone: string;
}

/**
 * @export
 * @namespace ServiceNewRequest
 */
// export namespace ServiceNewRequest {
//   /**
//    * @export
//    * @enum {string}
//    */
//   export enum ServiceTypeEnum {
//     Internet = <any>"internet",
//     Tv = <any>"tv",
//     OnlineTv = <any>"online_tv",
//     Intercom = <any>"intercom",
//     Cctv = <any>"cctv",
//     EquipmentRent = <any>"equipment_rent",
//     DigitalTv = <any>"digital_tv",
//     Telephony = <any>"telephony",
//   }
// }
/**
 * Услуга Интернет (Pon)
 * @export
 * @interface ServicePon
 */
export interface ServicePon {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServicePon
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServicePon
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServicePon
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServicePon
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServicePon
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServicePon
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServicePon
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServicePon
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServicePon
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServicePon
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServicePon
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServicePon
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServicePon
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServicePon
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServicePon
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServicePon
   */
  available_options: MainAvailablePlans;
  /**
   *
   * @type {ServiceExtIp}
   * @memberof ServicePon
   */
  external_ip?: ServiceExtIp;
  /**
   * Оборудование - уникально для ПОН
   * @type {string}
   * @memberof ServicePon
   */
  pon_device: string;
  /**
   * IP клиента
   * @type {string}
   * @memberof ServicePon
   */
  client_ip: string;
  /**
   * Список дополнительного оборудования
   * @type {Equipment}
   * @memberof ServicePon
   */
  equipment: Equipment;
}

/**
 * @export
 * @namespace ServicePon
 */

/**
 * Услуга Видеонаблюдение (Rec)
 * @export
 * @interface ServiceRec
 */
export interface ServiceRec {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceRec
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceRec
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceRec
   */
  show_unblock_btn: boolean;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceRec
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceRec
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceRec
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceRec
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceRec
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceRec
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceRec
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceRec
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceRec
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceRec
   */
  available_options: MainAvailablePlans;
  /**
   * Наименование услуги (Камера 1)
   * @type {string}
   * @memberof ServiceRec
   */
  camera_name: string;
  /**
   *
   * @type {RecBaseModules}
   * @memberof ServiceRec
   */
  modules_base: RecBaseModules;
  /**
   * Список дополнительных модулей
   * @type {Array<RecAdditionalModule>}
   * @memberof ServiceRec
   */
  modules_additional?: Array<RecAdditionalModule>;
  /**
   * Показывать ли кнопку Опции
   * @type {boolean}
   * @memberof ServiceRec
   */
  show_dop_options: boolean;
  /**
   * Показывать ли кнопку Редактировать (модули/опции)
   * @type {boolean}
   * @memberof ServiceRec
   */
  show_options_change_btn: boolean;
  /**
   * Список дополнительного оборудования
   * @type {Equipment}
   * @memberof ServiceRec
   */
  equipment: Equipment;
  /**
   * Код страницы условий для подключения / изменения модулей
   * @type {string}
   * @memberof ServiceRec
   */
  condition_block: Condition | null;
}

/**
 * @export
 * @namespace ServiceRec
 */

/**
 * Аренда оборудования. Подробная информация
 * @export
 * @interface ServiceRent
 */
export interface ServiceRent {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceRent
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceRent
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceRent
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceRent
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceRent
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceRent
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceRent
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceRent
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceRent
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceRent
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceRent
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceRent
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceRent
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceRent
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceRent
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceRent
   */
  available_options: MainAvailablePlans;
  /**
   * Показывать ли кнопку Выкуп оборудования
   * @type {boolean}
   * @memberof ServiceRent
   */
  show_equipment_payback_btn: boolean;
  /**
   * Сумма выкупа
   * @type {number}
   * @memberof ServiceRent
   */
  buyout_cost: number;
  /**
   * Примерная сумма расчета выкупа
   * @type {string}
   * @memberof ServiceRent
   */
  buyout_cost_description: string;
  /**
   * Наименование оборудования
   * @type {string}
   * @memberof ServiceRent
   */
  name_equipment: string;
  /**
   * Дата начала аренды
   * @type {string}
   * @memberof ServiceRent
   */
  rent_start_date: string;
  /**
   * Дата окончания аренды
   * @type {string}
   * @memberof ServiceRent
   */
  rent_end_date: string;
  /**
   * Условия предоставления услуги
   * @type {string}
   * @memberof ServiceRent
   */
  rent_terms: string;
}

/**
 * @export
 * @namespace ServiceRent
 */

/**
 * Краткая информация об индивидуальной услуге для главного экрана
 * @export
 * @interface ServiceSummary
 */
export interface ServiceSummary {
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceSummary
   */
  service_title: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceSummary
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceSummary
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceSummary
   */
  service_status: ServiceStatusEnum;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceSummary
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceSummary
   */
  additional_info: string;
  /**
   * Имя модуля для услуги Цифровое телевидение (ЦТВ)
   * @type {string}
   * @memberof ServiceSummary
   */
  dtv_module_name: string;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof ServiceSummary
   */
  plan_name: string;
  /**
   * Описание тарифа
   * @type {string}
   * @memberof ServiceSummary
   */
  plan_descr: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof ServiceSummary
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof ServiceSummary
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof ServiceSummary
   */
  fee_kind: FeeKindEnum;
  /**
   * Является ли этот тариф абонементом
   * @type {boolean}
   * @memberof ServiceSummary
   */
  is_ab: boolean;
  /**
   * Дата окончания абонемента
   * @type {string}
   * @memberof ServiceSummary
   */
  end_date?: string;
}

/**
 * @export
 * @namespace ServiceSummary
 */

export enum ServiceTypeEnum {
  Atv = 'atv',
  CollectiveAtv = 'collective_atv',
  Dtv = 'dtv',
  Ott = 'ott',
  Eth = 'eth',
  Pon = 'pon',
  Docsis = 'docsis',
  Tel = 'tel',
  Rent = 'rent',
  Rec = 'rec',
  UDomofon = 'uDomofon',
  CollectiveDomofon = 'collective_domofon',
  CollectiveRadio = 'collective_radio',
  CollectiveVideo = 'collective_video',
}

export enum ServiceStatusEnum {
  Blocked = <any>'blocked',
  Active = <any>'active',
  Suspended = <any>'suspended',
  NotSet = <any>'not_set',
}

/**
 * Услуга Телефония (Tel)
 * @export
 * @interface ServiceTel
 */
export interface ServiceTel {
  info_block: ServiceBlockInfo | null;
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceTel
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceTel
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceTel
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceTel
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceTel
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceTel
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceTel
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceTel
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceTel
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceTel
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceTel
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceTel
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceTel
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceTel
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceTel
   */
  available_options: MainAvailablePlans;
  /**
   * Номер телефона в рамках услуги Телефония
   * @type {string}
   * @memberof ServiceTel
   */
  phone_number: string;
}

/**
 * @export
 * @namespace ServiceTel
 */

/**
 * Детальная информация про Телевидение
 * @export
 * @interface ServiceTv
 */
export interface ServiceTv {
  /**
   * Порядок отображения услуг
   * @type {{ [key: string]: any; }}
   * @memberof ServiceTv
   */
  service_order: { [key: string]: any };
  /**
   * CID сервиса, на котором фокус
   * @type {string}
   * @memberof ServiceTv
   */
  focus_service_cid?: string;
  /**
   * Целевая услуга
   * @type {FocusServiceGroup}
   * @memberof ServiceTv
   */
  focus_service: FocusServiceGroup;
  /**
   * Является ли договор общим для всех услуг ТВ
   * @type {boolean}
   * @memberof ServiceTv
   */
  is_common_agreement: boolean;
  /**
   * Общий договор для всех услуг ТВ
   * @type {number}
   * @memberof ServiceTv
   */
  common_agreement: number | null;
  /**
   * Телевидение (Базовый)
   * @type {AtvGroup}
   * @memberof ServiceTv
   */
  atv?: AtvGroup;
  /**
   * Телевидение (Коллективное)
   * @type {CollectiveAtvGroup}
   * @memberof ServiceTv
   */
  collective_atv?: CollectiveAtvGroup;
  /**
   * ЦТВ
   * @type {DtvGroup}
   * @memberof ServiceTv
   */
  dtv?: DtvGroup;
  /**
   * ОТТ (Онлайн ТВ iPakt)
   * @type {OttGroup}
   * @memberof ServiceTv
   */
  ott?: OttGroup;
}
/**
 * Услуга Умный домофон (uDomofon)
 * @export
 * @interface ServiceUDomofon
 */
export interface ServiceUDomofon {
  /**
   * Показывать кнопку Сменить тариф / Редактировать
   * @type {boolean}
   * @memberof ServiceUDomofon
   */
  show_plan_change_btn: boolean;
  /**
   * Показывать кнопку Приостановить
   * @type {boolean}
   * @memberof ServiceUDomofon
   */
  show_block_btn: boolean;
  /**
   * Показывать кнопку Возобновить услугу
   * @type {boolean}
   * @memberof ServiceUDomofon
   */
  show_unblock_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServiceUDomofon
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServiceUDomofon
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServiceUDomofon
   */
  additional_info: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof ServiceUDomofon
   */
  service_cid: string;
  /**
   * Тип услуги (внутренний идентификатор)  * `atv` - Телевидение * `collective_atv` - Телевидение * `dtv` - Дополнительные пакеты ЦТВ * `ott` - Онлайн ТВ «iPakt» * `eth` - Интернет * `pon` - Интернет * `docsis` - Интернет * `tel` - Телефония * `rent` - Аренда * `rec` - Видеонаблюдение * `uDomofon` - Умный домофон * `collective_domofon` - Домофон * `collective_radio` - Радио * `collective_video` - Видеонаблюдение
   * @type {string}
   * @memberof ServiceUDomofon
   */
  service_type: ServiceTypeEnum;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServiceUDomofon
   */
  service_status: ServiceStatusEnum;
  /**
   * Адрес подключения услуги
   * @type {string}
   * @memberof ServiceUDomofon
   */
  service_location: string;
  /**
   * Итоговая стоимость по услуге
   * @type {TotalCostDetailed}
   * @memberof ServiceUDomofon
   */
  total_cost: TotalCostDetailed;
  /**
   * Приостановлена ли услуга
   * @type {boolean}
   * @memberof ServiceUDomofon
   */
  is_service_blocked: boolean;
  /**
   *
   * @type {ServiceBlockInfo}
   * @memberof ServiceUDomofon
   */
  info_block?: ServiceBlockInfo;
  /**
   *
   * @type {Plan}
   * @memberof ServiceUDomofon
   */
  plan?: Plan;
  /**
   *
   * @type {Schedule}
   * @memberof ServiceUDomofon
   */
  schedule: Schedule;
  /**
   * Список доступных опций
   * @type {MainAvailablePlans}
   * @memberof ServiceUDomofon
   */
  available_options: MainAvailablePlans;
  /**
   * Список дополнительного оборудования
   * @type {Equipment}
   * @memberof ServiceUDomofon
   */
  equipment: Equipment;
}

/**
 * @export
 * @namespace ServiceUDomofon
 */

/**
 * Все подключенные услуги клиента (краткая информация)
 * @export
 * @interface ServicesClient
 */
export interface ServicesClient {
  /**
   * Порядок отображения услуг
   * @type {{ [key: string]: string; }}
   * @memberof ServicesClient
   */
  service_order: { [key: string]: string };
  /**
   * Список услуг Интернет (Ethernet)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  eth?: Array<ServiceSummary>;
  /**
   * Список услуг Интернет (Pon)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  pon?: Array<ServiceSummary>;
  /**
   * Список услуг Интернет (Docsis)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  docsis?: Array<ServiceSummary>;
  /**
   * Список услуг Телевидение (Analog Television)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  atv?: Array<ServiceSummary>;
  /**
   * Список услуг Телевидение (Коллективный)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  collective_atv?: Array<ServiceSummary>;
  /**
   * Список услуг Телевидение (ЦТВ)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  dtv?: Array<ServiceSummary>;
  /**
   * Список услуг Телефония
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  tel?: Array<ServiceSummary>;
  /**
   * Список услуг Аренда (Rent)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  rent?: Array<ServiceSummary>;
  /**
   * Список услуг Видеонаблюдение (Rec)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  rec?: Array<ServiceSummary>;
  /**
   * Список услуг Телевидение (OTT)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  ott?: Array<ServiceSummary>;
  /**
   * Список услуг Умный домофон
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  u_domofon?: Array<ServiceSummary>;
  /**
   * Список услуг Радио (Коллективный)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  collective_radio?: Array<ServiceSummary>;
  /**
   * Список услуг Домофон (Коллективный)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  collective_domofon?: Array<ServiceSummary>;
  /**
   * Список услуг Видеонаблюдение (Коллективный)
   * @type {Array<ServiceSummary>}
   * @memberof ServicesClient
   */
  collective_video?: Array<ServiceSummary>;
}
/**
 * Детальная информация по всем услугам Видеонаблюдение
 * @export
 * @interface ServicesRec
 */
export interface ServicesRec {
  /**
   * Порядок отображения услуг
   * @type {{ [key: string]: any; }}
   * @memberof ServicesRec
   */
  service_order: { [key: string]: any };
  /**
   * CID сервиса, на котором фокус
   * @type {string}
   * @memberof ServicesRec
   */
  focus_service_cid?: string;
  /**
   * Список услуг Видеонаблюдение
   * @type {Array<ServiceRec>}
   * @memberof ServicesRec
   */
  rec?: Array<ServiceRec>;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof ServicesRec
   */
  service_title?: string;
  /**
   * Номер договора для услуги
   * @type {number}
   * @memberof ServicesRec
   */
  service_agr: number;
  /**
   * Дополнительная текстовая информация для пользователя
   * @type {string}
   * @memberof ServicesRec
   */
  additional_info: string;
  /**
   * Статус услуги  * `blocked` - Заблокирована * `active` - Активна * `suspended` - Приостановлена * `not_set` -
   * @type {string}
   * @memberof ServicesRec
   */
  service_status: ServicesRec.ServiceStatusEnum;
}

/**
 * @export
 * @namespace ServicesRec
 */
export namespace ServicesRec {
  /**
   * @export
   * @enum {string}
   */
  export enum ServiceStatusEnum {
    Blocked = <any>'blocked',
    Active = <any>'active',
    Suspended = <any>'suspended',
    NotSet = <any>'not_set',
  }
}
/**
 * Подписка на рассылку
 * @export
 * @interface Subscription
 */
export interface Subscription {
  recipient: string | null;
  /**
   * Тип подписки ('email', 'telegram', 'phone').
   * @type {string}
   * @memberof Subscription
   */
  sub_type: TypeSubscriptionEnum;
  /**
   * Описание типа подписки (например, 'Электронная почта').
   * @type {string}
   * @memberof Subscription
   */
  description: string;
  /**
   * Можно ли отписаться от рассылки
   * @type {boolean}
   * @memberof Subscription
   */
  is_change_sub: boolean;
  /**
   * Подписка активна (подключена)
   * @type {boolean}
   * @memberof Subscription
   */
  is_subscribed: boolean;
  /**
   * Список доступных опций в подписке
   * @type {Array<string>}
   * @memberof Subscription
   */
  subscription_items?: Array<string>;
}
/**
 * Список всех доступных подписок с их текущим статусом и описанием
 * @export
 * @interface SubscriptionManagement
 */
export interface SubscriptionManagement {
  announces: Announce[];
  /**
   * Список доступных подписок
   * @type {Array<Subscription>}
   * @memberof SubscriptionManagement
   */
  subscriptions: Array<Subscription>;
}
/**
 * Действие с определенным типом подписки.  Примеры: {     \"type_subscription\": \"email\",     \"value\": \"example@mail.com\",     \"action\": \"subscribe\" }, {     \"type_subscription\": \"telegram\",     \"value\": \"@username\",     \"action\": \"unsubscribe\" }
 * @export
 * @interface SubscriptionRequest
 */
export interface SubscriptionRequest {
  /**
   * Тип подписки  * `email` - Электронная почта * `telegram` - Телеграм
   * @type {string}
   * @memberof SubscriptionRequest
   */
  type_subscription: TypeSubscriptionEnum;
  /**
   * Почта или телефон, связанное с выбранным типом подписки
   * @type {string}
   * @memberof SubscriptionRequest
   */
  value: string;
  /**
   * Действие - подписаться / отписаться  * `subscribe` - Подписаться * `unsubscribe` - Отписаться
   * @type {string}
   * @memberof SubscriptionRequest
   */
  action: ActionEnum;
}

/**
 * @export
 * @namespace SubscriptionRequest
 */

export enum TypeSubscriptionEnum {
  Email = <any>'email',
  Telegram = <any>'telegram',
}
export enum ActionEnum {
  Subscribe = <any>'subscribe',
  Unsubscribe = <any>'unsubscribe',
}
/**
 * Обновление состояния подписок
 * @export
 * @interface SubscriptionUpdateRequest
 */
export interface SubscriptionUpdateRequest {
  /**
   * Список подписок для обновления
   * @type {Array<SubscriptionRequest>}
   * @memberof SubscriptionUpdateRequest
   */
  updates: Array<SubscriptionRequest>;
}
/**
 * Условия тарифа для Телефонии
 * @export
 * @interface TariffConditionTel
 */
export interface TariffConditionTel {
  /**
   * Условия - тип звонков
   * @type {string}
   * @memberof TariffConditionTel
   */
  condition: string;
  /**
   * Стоимость звонка
   * @type {string}
   * @memberof TariffConditionTel
   */
  cost: string;
}
/**
 *
 * @export
 * @interface TotalCostDetailed
 */
export interface TotalCostDetailed {
  /**
   * Общая стоимость по услуге
   * @type {number}
   * @memberof TotalCostDetailed
   */
  cost: number;
  /**
   * Период оплаты
   * @type {number}
   * @memberof TotalCostDetailed
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )
   * @type {string}
   * @memberof TotalCostDetailed
   */
  fee_kind: FeeKindEnum;
}
/**
 *
 * @export
 * @interface UnblockServiceRequest
 */
export interface UnblockServiceRequest {
  /**
   * Адрес подключения
   * @type {string}
   * @memberof UnblockServiceRequest
   */
  connection_address: string;
  /**
   * ФИО
   * @type {string}
   * @memberof UnblockServiceRequest
   */
  full_name: string;
  /**
   *
   * @type {string}
   * @memberof UnblockServiceRequest
   */
  contact_phone: string;
}
/**
 * Данные для подключения нового тарифа
 * @export
 * @interface UpdPlanNowRequest
 */
export interface UpdPlanNowRequest {
  /**
   * ID Нового плана (тарифа)
   * @type {number}
   * @memberof UpdPlanNowRequest
   */
  new_plan_id: number;
  new_abon_id: number | null;
  start_date: string | null;
  /**
   * ID текущего плана (тарифа)
   * @type {number}
   * @memberof UpdPlanNowRequest
   */
  current_plan_id: number;
}
/**
 * Данные для подключения нового тарифа по расписанию
 * @export
 * @interface UpdPlanScheduleRequest
 */
export interface UpdPlanScheduleRequest {
  /**
   * ID Нового плана (тарифа)
   * @type {number}
   * @memberof UpdPlanScheduleRequest
   */
  new_plan_id: number;
  /**
   * ID текущего плана (тарифа)
   * @type {number}
   * @memberof UpdPlanScheduleRequest
   */
  current_plan_id: number;
  /**
   * Дата изменения тарифного плана
   * @type {string}
   * @memberof UpdPlanScheduleRequest
   */
  start_date: string;
}

export interface ResponseAvailablePlansForChange {
  /**
   *
   * @type {AvailablePlansForChange}
   * @memberof ResponseAvailablePlansForChange
   */
  data: AvailablePlansForChange;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseAvailablePlansForChange
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseAvailablePlansForChange
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseAvailablePlansForChange
   */
  client_message: string;
}

export interface AvailablePlansForChange {
  /**
   * Показывать ли кнопку Изменить сразу
   * @type {boolean}
   * @memberof AvailablePlansForChange
   */
  show_change_now_btn: boolean;
  /**
   * Показывать ли кнопку Запланировать
   * @type {boolean}
   * @memberof AvailablePlansForChange
   */
  show_schedule_btn: boolean;
  /**
   * Имя услуги для вывода на экран
   * @type {string}
   * @memberof AvailablePlansForChange
   */
  service_title: string;
  /**
   * Уникальный ID услуги
   * @type {string}
   * @memberof AvailablePlansForChange
   */
  service_cid: string;
  /**
   * Код страницы условий для подключения абонемента
   * @type {string}
   * @memberof AvailablePlansForChange
   */
  condition_block: Condition | null;

  /**
   * Дополнительная информация для информационного сообщения на экране Подтвердить смену
   * @type {string}
   * @memberof AvailablePlansForChange
   */
  schedule_info?: string;
  /**
   *
   * @type {Plan}
   * @memberof AvailablePlansForChange
   */
  current_plan: Plan;
  /**
   * Доступные тарифы
   * @type {Array<AvailablePlan>}
   * @memberof AvailablePlansForChange
   */
  available_plans?: Array<AvailablePlan>;
}
/**
 * Доступная новая услуга
 * @export
 * @interface AvailableService
 */

export interface AvailablePlan {
  tooltip: string | null;
  /**
   * ID тарифа
   * @type {number}
   * @memberof AvailablePlan
   */
  plan_id: number;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof AvailablePlan
   */
  plan_name: string;
  /**
   * Описание тарифа
   * @type {string}
   * @memberof AvailablePlan
   */
  plan_descr: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof AvailablePlan
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof AvailablePlan
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof AvailablePlan
   */
  fee_kind: FeeKindEnum;
  /**
   * Скорость тарифа (Мбит/с). Только для услуги Интернет
   * @type {number}
   * @memberof AvailablePlan
   */
  speed?: number;
  /**
   * Является ли этот тариф абонементом
   * @type {boolean}
   * @memberof AvailablePlan
   */
  is_ab: boolean;
  /**
   * Порядковый номер тарифа
   * @type {number}
   * @memberof AvailablePlan
   */
  display_order: number;
  /**
   * Имя тарифа. Формат '_период_ - _стоимость_'
   * @type {string}
   * @memberof AvailablePlan
   */
  plan_full_name: string;
  /**
   * Доступные абонементы для тарифа
   * @type {Array<AvailableAbonements>}
   * @memberof AvailablePlan
   */
  abonements?: Array<AvailableAbonements>;
}
export interface AvailableAbonements {
  /**
   * ID тарифа
   * @type {number}
   * @memberof AvailableAbonements
   */
  plan_id: number;
  /**
   * Имя тарифа
   * @type {string}
   * @memberof AvailableAbonements
   */
  plan_name: string;
  /**
   * Описание тарифа
   * @type {string}
   * @memberof AvailableAbonements
   */
  plan_descr: string;
  /**
   * Стоимость тарифа
   * @type {number}
   * @memberof AvailableAbonements
   */
  plan_fee: number;
  /**
   * Период оплаты тарифа
   * @type {number}
   * @memberof AvailableAbonements
   */
  fee_period: number;
  /**
   * Вид оплаты тарифа - день|месяц|год ( day / month / year )  * `day` - Day * `month` - Month * `year` - Year
   * @type {string}
   * @memberof AvailableAbonements
   */
  fee_kind: FeeKindEnum;
  /**
   * Скорость тарифа (Мбит/с). Только для услуги Интернет
   * @type {number}
   * @memberof AvailableAbonements
   */
  speed?: number;
  /**
   * Является ли этот тариф абонементом
   * @type {boolean}
   * @memberof AvailableAbonements
   */
  is_ab: boolean;
  /**
   * Порядковый номер абонемента для отображения
   * @type {number}
   * @memberof AvailableAbonements
   */
  display_order: number;
  /**
   * Имя абонемента. Формат '_период_ - _стоимость_ (скорость)'
   * @type {string}
   * @memberof AvailableAbonements
   */
  abonement_full_name: string;
  /**
   * Всплывающая дополнительная подксказка об абонементе
   * @type {string}
   * @memberof AvailableAbonements
   */
  tooltip: string;
}

export interface ResponseExtraOptions {
  data: ExtraOptions;
  status: boolean;
  message: string;
  client_message: string;
}

export interface ExtraOptions {
  title: string;
  connection_address: string;
  full_name: string;
  agreement: number;
  contact_phone: string;
  condition_block: Condition | null;

  title_context: string;
  text_context: string;
  is_attach_doc: boolean;
  text_btn: string;
  additional_data: any;
  condition_text: string;
  condition_text_link: string;
}

export interface ExtraOptionsRequestRequest {
  connection_address: string;
  full_name: string;
  contact_phone: string;
  text_context: string;
  additional_data: any;
}

export interface ResponseWelcome {
  data: Welcome;
  status: boolean;
  message: string;
  client_message: string;
}

export interface Welcome {
  website_url: string;
  youtube_url: string;
  vk_url: string;
  reset_password_url: string;
  privacy_policy_url: string;
  old_lk_url: string;
  telegram_url: string;
  old_lk_show: boolean;
  old_lk_text: string;
}

export interface ServiceNewRequestRequest {
  contact_phone: string;
  service_type: string;
  plan_id: number;
  plan_name: string;
  call_time: {
    start: string;
    end: string;
  };
}

export interface ResponseUsefulInfo {
  data: UsefulInfo;
  status: boolean;
  message: string;
  client_message: string;
}

export interface UsefulInfo {
  title: string;
  items: UsefulInfoItem[];
}

export interface UsefulInfoItem {
  title: string;
  url: string;
  icon_code: string;
  order_number: number;
}

export interface ResponseMainExtraOptions {
  data: MainExtraOptions;
  status: boolean;
  message: string;
  client_message: string;
}

export interface MainExtraOptions {
  service_title: string;
  service_type: ServiceTypeEnum;
  service_cid: string;
  spec_offers: ISpecOffers;
  extra_plans: { [key: string]: AvailablePlans } | null;
}
export interface ISpecOffers {
  category_code: string;
  category_name: string;
  items: SpecOffer[];
}

export interface SpecOffer {
  code_promo: string;
  title: string;
  paragraph_one: string;
  paragraph_two: string;
  button_text: string;
  button_url: string;
  link_text: string;
  link_url: string;
}

export interface ResponseBanners {
  status?: boolean;
  message: string;
  client_message: string;
  data: Banners;
}

export interface Banners {
  banners: Banner[];
}

export interface Banner {
  title: string;
  description: string;
  redirect_url: string;
  type_banner: string;
}

export interface ResponseReceiptConfirm {
  status?: boolean;
  message: string;
  client_message: string;
  data: ReceiptConfirm;
}

export interface ReceiptConfirm {
  title: string;
  description: string;
  agreement: number;
  agreement_description: string;
  balance: string;
  balance_description: string;
  cost: string;
  cost_description: string;
  recommend_pay: string;
  recommend_pay_description: string;
  additional_text: string;
  deposit_amount: number
}

export interface ResponseServiceCollectiveRadio {
  data: ServiceCollectiveRadio;
  /**
   * Результат выполнения запроса (True/False)
   * @type {boolean}
   * @memberof ResponseServiceCollectiveDomofon
   */
  status?: boolean;
  /**
   * Внутреннее сообщение
   * @type {string}
   * @memberof ResponseServiceCollectiveDomofon
   */
  message: string;
  /**
   * Сообщение для пользователя
   * @type {string}
   * @memberof ResponseServiceCollectiveDomofon
   */
  client_message: string;
}

export interface ResponseNews {
  data: News;
}
export interface News {
  title: string;
  items: { [key: string]: NewsItem };
}

export interface NewsItem {
  title: string;
  description: string;
  published: string;
  full_content: string;
}

export interface ResponseMainSpecPlans {
  data: MainSpecPlans;
}

export interface MainSpecPlans {
  title: string;
  available_plans: { [key: string]: AvailablePlans | null };

  spec_offers: {
    category_code: string;
    category_name: string;
    items: SpecOffer[];
  };
}



export interface ResponseNotifyPool{
  data: NotifyPool;
  status?: boolean;
  message: string;
  client_message: string;

}

export interface NotifyPool{
  agreement: string;
  start_date: string;
  end_date: string;
  has_new_messages: boolean;
  messages:Message[]

}


export interface Message{
  id: number;
  title:string;
  body:string;
  created: string;
  status: string;
  is_read: 'new' | 'read';
  notif_type:{
    type:'system' | 'default';
    text:string;
  }
}


export interface NotifyStatusRequest{
  notification_id: number;
  new_status: 'new' | 'read';

}

export interface RecModulesRequest{
  modules:RecModuleRequest[] | null
}

export interface RecModuleRequest{
  key:string;
  plan_id:string

}
//Наш 
export interface Condition {
	text: string
	link: string
	code: string
	checkboxes: string[]
}
