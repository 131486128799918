import { configureStore } from '@reduxjs/toolkit'

import { logger } from 'redux-logger'
import clearStoreMiddleware from 'src/app/providers/redux/model/clearStoreMiddleware'
import rootReducer from 'src/app/providers/redux/model/rootReducer'
import { DEBUG_REDUX_ENABLED } from 'src/config'

const isProduction = process.env.REACT_APP_ENV === 'production'

export const store = configureStore({
	reducer: rootReducer,
	middleware: getDefaultMiddleware =>
		isProduction || !DEBUG_REDUX_ENABLED
			? getDefaultMiddleware().concat(clearStoreMiddleware)
			: getDefaultMiddleware().concat(logger).concat(clearStoreMiddleware),
	devTools: !isProduction
})

export type RootState = ReturnType<typeof rootReducer>
export type AppDispatch = typeof store.dispatch
