import { FC, ReactNode, useEffect, useState } from 'react'

import { AxiosResponse } from 'axios'
import { Controller, SubmitHandler, useForm } from 'react-hook-form'
import { API_DOMAIN } from 'src/api/service/endpoints'
import { getFromLocalStorage } from 'src/api/service/helpers/localStorage.helper'
import $api from 'src/api/service/request'
import LinkUi from 'src/components/ui/LinkUi/LinkUi'
import AnnounceUi from 'src/components/ui/announce/Announce'
import Button from 'src/components/ui/button/Button'
import CalendarUi from 'src/components/ui/calendar/CalenedarUi'
import FileUploadUi from 'src/components/ui/fileUpload/FileUploadUi'
import Input from 'src/components/ui/input/Input'
import Loader from 'src/components/ui/loader/Loader'
import SelectUi, { ISelectOptions } from 'src/components/ui/select/SelectUi'
import Svg from 'src/components/ui/svg/Svg'
import useModal from 'src/hooks/useModal'
import { PAYMENT } from 'src/routes/data'
import { PaymentError, ResponsePaymentError } from 'src/types/swagger'
import { formatDate } from 'src/utils.js/formatDate'
import { v4 as uuidv4 } from 'uuid'

import Panel from '../../panel/Panel'

import './PaymentError.scss'

type FormValues = {
	payment_gate_name: string
	aggr: number
	sum: string
	file: File
	file_check: File
}

type FileOrEmptyString = File | ''

const PaymentErrorR: FC = () => {
	const {
		register,
		handleSubmit,
		watch,
		control,
		formState: { errors },
		getValues,
		clearErrors,
		setError
	} = useForm<FormValues>({ mode: 'onChange' })

	const [date, setDate] = useState(new Date())
	const [data, setData] = useState<PaymentError | null>(null)
	const [file, setFile] = useState<File | null>()
	const [checks, setChecks] = useState<Array<File>>([])
	const [renderedChecks, setRenderedChecks] = useState<ReactNode[]>([])

	const { open } = useModal()

	const [isLoading, setIsLoading] = useState(false)
	const [paymentList, setPaymentList] = useState<ISelectOptions[] | null | undefined>(null)
	const [currentBank, setCurrentBank] = useState<string>('')

	//Запрос данных
	const fetchData = async () => {
		setIsLoading(true)
		try {
			const response: AxiosResponse<ResponsePaymentError> = await $api.get(
				`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/payments/errors/`
			)

			const { data } = response.data

			if (data) {
				setData(data)
			}
		} catch (error: any) {
			console.error('Failed to fetch payment:', error.message)
		} finally {
			setIsLoading(false)
		}
	}
	//Запрос данных

	//Первичная загрузка
	useEffect(() => {
		fetchData()
	}, [])
	//Первичная загрузка

	//Обработка значений в селект
	useEffect(() => {
		if (data) {
			const paymentGateList = data?.payment_gate?.map(item => {
				return {
					value: item.name,
					label: item.name
				}
			})
			setPaymentList(paymentGateList)

			setCurrentBank(data?.payment_gate?.[0].name)
		}
	}, [data])
	//Обработка значений в селект

	//Обновления списка выводимых прикреплённых чеков
	useEffect(() => {
		let newArrChecks = []
		let count = 0
		for (let i = 0; i < checks.length; i++) {
			const keyId = uuidv4()

			newArrChecks.push(
				<FileUploadUi
					key={keyId}
					text={checks[i].name}
					fileValue={checks[i]}
					onChange={handleChangeCheck(i)}
					onDelete={() => handleCheckDelete(i)}
					maxSizeMB={1}
					allowedTypes={[
						'text/plain',
						'application/msword',
						'application/pdf',
						'image/png',
						'image/jpeg'
					]}
				/>
			)

			count++
		}

		if (checks.length === 0) {
			newArrChecks.push(
				<Controller
					key="controller-key"
					name="file_check"
					control={control}
					rules={{ required: 'Файл обязателен' }}
					render={({ field: { onChange } }) => (
						<FileUploadUi
							text="Чек об оплате"
							onChange={uploadedFile => {
								handleChangeCheckController(count, uploadedFile)
							}}
							onDelete={uploadedFile => {
								handleCheckDelete(count)
								onChange(uploadedFile)
							}}
							maxSizeMB={1}
							allowedTypes={[
								'text/plain',
								'application/msword',
								'application/pdf',
								'image/png',
								'image/jpeg'
							]}
						/>
					)}
				/>
			)
			count++
		}
		if (checks.length < (data ? data?.payment_receipts?.max_files : 6) && checks.length > 0) {
			const baseText = checks.length < 1 ? 'Чек об оплате' : 'Добавить ещё'
			const keyId = uuidv4()
			newArrChecks.push(
				<FileUploadUi
					key={keyId}
					text={baseText}
					onChange={handleChangeCheck(count)}
					onDelete={() => handleCheckDelete(count)}
					maxSizeMB={1}
					allowedTypes={[
						'text/plain',
						'application/msword',
						'application/pdf',
						'image/png',
						'image/jpeg'
					]}
				/>
			)
		}

		setRenderedChecks(newArrChecks)
	}, [checks])

	//Обновления списка выводимых прикреплённых чеков

	//Смена одного из выбранных чеков либо его добавление
	const handleChangeCheck = (index: number) => (file: File) => {
		let newArr = checks

		if (checks.length === 0) {
			newArr[0] = file
			setChecks(checks => [...newArr])
		} else {
			newArr[index] = file
			setChecks(checks => [...newArr])
		}
	}
	const handleChangeCheckController = (index: number, file: File) => {
		let newArr = checks

		if (checks.length === 0) {
			newArr[0] = file
			setChecks(checks => [...newArr])
		} else {
			newArr[index] = file
			setChecks(checks => [...newArr])
		}
		clearErrors('file_check')
	}

	const handleCheckDelete = (index: number) => {
		if (checks.length === 1) {
			setError('file_check', { type: 'required', message: 'Файл обязателен' })
		}
		let newArr: FileOrEmptyString[] = [...checks]
		newArr[index] = ''
		let filteredArray: File[] = newArr.filter((item): item is File => item instanceof File)
		setChecks(filteredArray)
	}
	//Смена одного из выбранных чеков либо его добавление

	//Отправка формы

	const onSubmit: SubmitHandler<FormValues> = formData1 => {
		const fetchData = async () => {
			setIsLoading(true)

			const formData = new FormData()

			formData.append('connection_address', data!.connection_address)
			formData.append('full_name', data!.full_name!)
			formData.append('contact_phone', data!.contact_phone)
			formData.append('error_agreement', formData1.aggr.toString())
			formData.append('amount', formData1.sum)
			formData.append('payment_date', formatDate(date, 'send')!)
			formData.append('payment_gate_name', currentBank)
			if (file && checks.length) {
				formData.append('file', file)

				checks.forEach(item => {
					formData.append('file', item)
				})
			}

			try {
				const response = await $api.post(
					`${API_DOMAIN}clients/${getFromLocalStorage('agreement')}/payments/errors/`,
					formData
				)

				open({
					text:
						response?.data?.data?.title ||
						response?.data?.client_message ||
						'Изменения сохранены',
					modalType: 'success',
					isReload: true,
					description: response?.data?.data?.description || ''
				})
			} catch (error: any) {
				open({
					text:
						error.response?.data?.data?.title ||
						error.response.data.client_message ||
						'Ошибка',
					description: error.response?.data?.data?.description || '',
					modalType: 'error'
				})
			} finally {
				setIsLoading(false)
			}
		}

		fetchData()
	}
	//Отправка формы

	if (isLoading) {
		return <Loader state={isLoading} />
	}

	return (
		data && (
			<div className="services-page connected-page connected-page--default">
				<Panel
					text={'Заявление об ошибочном платеже'}
					href={PAYMENT + '/balance'}
					linkText={'Оплата'}
				/>

				<section className="section section--first-panel r-payment-error">
					<div className="container">
						<div className="section__container">
							<div className="services-page__delay">
								<h1 className="section__title">Заявление об ошибочном платеже</h1>
								<div className="connected-page__inner">
									<div className="connected-page__accordion-heading">
										Данные абонента
									</div>
									<div className="connected-page__inner">
										<div className="services-page__delay-wrapper">
											<div className="services-page__delay-inner">
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														ФИО
													</div>
													<div className="connected-page__text">
														{data.full_name}
													</div>
												</div>
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														Номер договора
													</div>
													<div className="connected-page__text">
														{data.agreement}
													</div>
												</div>
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														Номер телефона
													</div>
													<div className="connected-page__text">
														{data.contact_phone}
													</div>
												</div>
												<div className="services-page__delay-item">
													<div className="services-page__card-label">
														Адрес подключения
													</div>
													<div className="connected-page__text">
														{data.connection_address}
													</div>
												</div>
											</div>
										</div>
									</div>
								</div>

								<form
									className="connected-page__inner react-close-payment"
									onSubmit={handleSubmit(onSubmit)}
								>
									<div className="connected-page__accordion-heading">
										Данные ошибочного платежа
									</div>
									<div className="services-page__inner">
										<div className="services-page__delay-wrapper">
											<div className="services-page__delay-grid">
												<div className="services-page__delay-item">
													<div className="services-page__delay-tooltip tooltip tooltip--alt">
														<div className="section__label default">
															Дата запуска
														</div>
													</div>
													<div className="r-payment-error__calendar">
														<CalendarUi
															value={date}
															setValue={setDate}
															svg={true}
															// onClick={handleShow}
															isButtonVisible={false}
														/>
													</div>
												</div>

												{/* //Платежная система */}
												<div className="services-page__delay-item">
													<div className="section__label">
														Платежная система
													</div>

													<div className="react-payment-error__select">
														{paymentList && (
															<SelectUi
																//@ts-ignore
																setValue={setCurrentBank}
																optionsValue={paymentList}
																name="payment_gate_name"
																options={{
																	required: 'Заполните поле'
																}}
															/>
														)}
													</div>
												</div>
												{/* //Платежная система */}

												<div className="services-page__delay-item">
													<div className="section__label">
														Ошибочный номер договора
													</div>
													<Input
														register={register}
														filled={!!watch('aggr')}
														name="aggr"
														error={errors.aggr}
														options={{
															required: 'Заполните поле',
															pattern: {
																value: /^\d+$/,
																message: 'Неверный формат'
															}
														}}
													/>
												</div>
												<div className="services-page__delay-item">
													<div className="section__label">Сумма</div>
													<Input
														register={register}
														filled={!!watch('sum')}
														name="sum"
														error={errors.sum}
														options={{
															required: 'Заполните поле',
															pattern: {
																value: /^\d+$/,
																message: 'Неверный формат'
															}
														}}
													/>
												</div>
											</div>
											{data.submission_conditions && (
												<div className="r-payment-error__info">
													<AnnounceUi
														text={data.submission_conditions.text}
														title={data.submission_conditions.title}
													/>
												</div>
											)}
											{data?.templates && (
												<div className="r-payment-error__download">
													<div className="r-payment-error__download-list">
														{data?.templates?.map((item, index) => {
															return (
																<LinkUi
																	key={item.link}
																	href={item.link}
																	text={item.title}
																	svgNode={
																		<Svg type="download" />
																	}
																	download
																/>
															)
														})}
													</div>
												</div>
											)}

											{/* //инпут для загрузки файлов */}
											<div className="r-payment-error__files">
												<div className="r-payment-error__files-row">
													<div className="r-payment-error__files-col">
														<p className="r-payment-error__files-title connected-page__accordion-heading">
															{data?.statement?.title}
														</p>
														<div className="r-payment-error__files-file">
															<Controller
																name="file"
																control={control}
																rules={{
																	required: 'Файл обязателен'
																}}
																render={({
																	field: { onChange }
																}) => (
																	<FileUploadUi
																		text={
																			data?.statement
																				?.description
																		}
																		onChange={uploadedFile => {
																			onChange(uploadedFile)
																			setFile(uploadedFile)
																		}}
																		onDelete={() => {
																			setFile(null)
																			onChange(null)
																		}}
																		fileValue={file}
																		maxSizeMB={1}
																		allowedTypes={[
																			'text/plain',
																			'application/msword',
																			'application/pdf',
																			'image/png',
																			'image/jpeg'
																		]}
																	/>
																)}
															/>
															{errors.file && (
																<p className="input-error">
																	{errors.file.message}
																</p>
															)}
														</div>
														<p className="r-payment-error__files-info services-page__card-label">
															{data.statement.attachment_instructions}
														</p>
													</div>
													<div className="r-payment-error__files-col">
														<p className="r-payment-error__files-title connected-page__accordion-heading">
															Чеки об оплате
														</p>
														<div className="r-payment-error__files-recipes r-payment-error__checks">
															{renderedChecks}
															{errors.file_check && (
																<p className="input-error input-error_checks">
																	{errors.file_check.message}
																</p>
															)}
														</div>
														<p className="r-payment-error__files-info services-page__card-label">
															{
																data.payment_receipts
																	.attachment_instructions
															}
														</p>
													</div>
												</div>
											</div>
											{/* //инпут для загрузки файлов */}
										</div>
									</div>
									<div className="services-page__delay-bot">
										<div className="services-page__delay-btn">
											<Button
												text={'Отменить'}
												size={'small'}
												theme={'outline'}
												href={PAYMENT + '/balance'}
											/>
										</div>
										<div className="services-page__delay-btn">
											<Button
												text={'Отправить'}
												size={'small'}
												type={'submit'}
											/>
										</div>
									</div>
								</form>
							</div>
						</div>
					</div>
				</section>
			</div>
		)
	)
}

export default PaymentErrorR
