import React, { useEffect, useState } from 'react'

import InputError from '../inputError/InputError'

import './FileUploadUi.scss'

interface IProps {
	text: string
	accept?: string
	maxSizeMB?: number // Максимальный размер файла в мегабайтах
	allowedTypes?: string[] // Разрешенные типы файлов
	onChange?: (fileData: File) => void // Теперь передаем содержимое файла в виде строки
	fileValue?: File | null
	onDelete?: (index?: number) => void
	value?: string | ''
}

const FileUploadUi = (props: IProps) => {
	const { text, accept, maxSizeMB, allowedTypes, onChange, onDelete, fileValue } = props
	const [file, setFile] = useState<File | null>(fileValue ? fileValue : null)
	const [error, setError] = useState<string | null>(null)
	const [value, setValue] = useState<string | ''>('')

	// Обработка загрузки файла
	const onUpload = function (e: React.ChangeEvent<HTMLInputElement>) {
		setError(null) // Сбрасываем предыдущие ошибки
		if (!e.target.files) return
		const uploadedFile = e.target.files[0]
		const fileSizeMB = uploadedFile?.size / (1024 * 1024) // Размер файла в мегабайтах

		// Проверяем размер файла и тип файла
		if (maxSizeMB && fileSizeMB > maxSizeMB) {
			setError(`Файл слишком большой, максимальный размер ${maxSizeMB}МБ`)
		} else if (allowedTypes && !allowedTypes.includes(uploadedFile.type)) {
			setError(`Недопустимый тип файла`)
		} else {
			setFile(uploadedFile)
			if (onChange) {
				onChange(uploadedFile)
			}
		}
	}
	//Обработка удаления загруженного файла
	const onDeleteFile = () => {
		setFile(null)
		setValue('')
		onDelete?.()
	}

	return (
		<div className="card flex justify-content-center">
			<div className="react-file">
				<div className="react-file__inner">
					<label className="react-file__label">
						<input
							className="react-file__input"
							type="file"
							onChange={onUpload}
							accept={accept}
							value={value}
						/>
						<div className="react-file__icon">
							<svg
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M20.7579 9.97761C20.6039 9.82114 20.3951 9.73324 20.1773 9.73324C19.9596 9.73324 19.7508 9.82114 19.5968 9.97761L10.6524 19.1077C10.2711 19.4953 9.81844 19.8027 9.32027 20.0125C8.8221 20.2222 8.28815 20.3302 7.74892 20.3303C6.65988 20.3303 5.61542 19.8907 4.84531 19.1081C4.07519 18.3255 3.6425 17.264 3.64242 16.1571C3.64235 15.0503 4.07489 13.9887 4.8449 13.206L13.5076 4.36552C13.9709 3.90221 14.5954 3.6437 15.2453 3.64631C15.8952 3.64892 16.5178 3.91244 16.9774 4.37946C17.437 4.84648 17.6964 5.47917 17.6992 6.13971C17.7019 6.80025 17.4477 7.43513 16.9919 7.90604L8.32921 16.7465C8.17298 16.8986 7.96498 16.9835 7.74862 16.9835C7.53227 16.9835 7.32427 16.8986 7.16804 16.7465C7.01409 16.59 6.92761 16.3777 6.92761 16.1564C6.92761 15.9351 7.01409 15.7228 7.16804 15.5663L14.8807 7.69154C15.0302 7.53413 15.113 7.3233 15.1111 7.10446C15.1093 6.88562 15.0229 6.67628 14.8707 6.52153C14.7184 6.36679 14.5124 6.27901 14.2971 6.27711C14.0818 6.27521 13.8744 6.35933 13.7195 6.51137L6.00688 14.3862C5.77807 14.6187 5.59657 14.8947 5.47274 15.1985C5.34891 15.5023 5.28517 15.828 5.28517 16.1568C5.28517 16.4857 5.34891 16.8113 5.47274 17.1151C5.59657 17.419 5.77807 17.695 6.00688 17.9275C6.47629 18.3828 7.1002 18.6368 7.74904 18.6368C8.39787 18.6368 9.02178 18.3828 9.49119 17.9275L18.1531 9.08622C18.907 8.30024 19.3258 7.24405 19.3189 6.14644C19.3119 5.04883 18.8798 3.99818 18.1161 3.22209C17.3524 2.446 16.3187 2.00696 15.2387 2.00008C14.1588 1.9932 13.1197 2.41904 12.3465 3.18534L3.68373 12.0258C2.60566 13.1215 2 14.6077 2 16.1573C2 17.7068 2.60566 19.193 3.68373 20.2887C4.76181 21.3844 6.22399 22 7.74862 22C9.27325 22 10.7354 21.3844 11.8135 20.2887L20.7579 11.1611C20.8346 11.0836 20.8955 10.9914 20.9371 10.8898C20.9786 10.7882 21 10.6793 21 10.5694C21 10.4594 20.9786 10.3505 20.9371 10.2489C20.8955 10.1474 20.8346 10.0552 20.7579 9.97761Z"
									fill="#0076D8"
								/>
							</svg>
						</div>
						<div className="react-file__text">{file ? file.name : text}</div>
					</label>
					{file && (
						<div className="react-file__delete" onClick={onDeleteFile}>
							<svg
								width={24}
								height={24}
								viewBox="0 0 24 24"
								fill="none"
								xmlns="http://www.w3.org/2000/svg"
							>
								<path
									d="M16.9492 7.05029L7.04972 16.9498"
									stroke="#929CA5"
									strokeWidth={2}
								/>
								<path
									d="M7.05078 7.05029L16.9503 16.9498"
									stroke="#929CA5"
									strokeWidth={2}
								/>
							</svg>
						</div>
					)}
				</div>

				{error && <InputError text={error} />}
			</div>
		</div>
	)
}

export default FileUploadUi
