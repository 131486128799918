import { FC, ReactNode } from 'react'

import './LinkUi.scss'

interface IProps {
	text: string
	href: string
	download?: boolean
	svgNode?: ReactNode
}

const LinkUi: FC<IProps> = ({ text, href, download, svgNode }) => {
	return (
		<a
			className="text-link"
			href={href}
			download={download ? download : ''}
			rel="noreferrer"
			target="_blank"
		>
			<div className="text-link__inner">
				{svgNode && <div className="text-link__icon">{svgNode}</div>}
				<p className="text-link__text">{text}</p>
			</div>
		</a>
	)
}

export default LinkUi
